import { NavLink } from "react-router-dom";
import { handleMenu } from "./menu";
import { handeLeaveSideBar, handeOnHoverSideBar } from "../components/const";
const MenuAdnin = () => {
    return (
        <ul className="metismenu " id="menu" onMouseOver={() => handeOnHoverSideBar()} onMouseLeave={() => handeLeaveSideBar()}>
            <li>
                <NavLink to='/'>
                    <div className="parent-icon"><i className='bx fs-4 bx-home-circle'></i>
                    </div>
                    <div className="menu-title">Tableau de bord</div>
                </NavLink>
            </li>
            <li>
                <NavLink to='/products/add' >
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-category"></i>
                    </div>
                    <div className="menu-title">Gestion Produits</div>
                </NavLink>
            </li>

            <li>
                <NavLink to='/products/stock-actuel' hidden>
                    <i className="bx fs-4 bx-right-arrow-alt">
                    </i>Stock Actuel
                </NavLink>
            </li>
            <li>
                <NavLink to='/magasins/liste'>
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-store "></i>
                    </div>
                    <div className="menu-title">Gestion Magasins</div>
                </NavLink>
            </li>
            <li>
                <NavLink to='/conteneurs'>
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-cube "></i>
                    </div>
                    <div className="menu-title"> Conteneurs & Stocks</div>
                </NavLink>
            </li>
            <li>
                <NavLink to='/approvisions' >
                    <div className="parent-icon">  <i className="bx fs-4 bx-right-top-arrow-circle"></i></div>
                    <div className="menu-title">Gestion Appro</div>
                </NavLink>
            </li>


            <li className="dropdwon" hidden>
                <a className=" bg-secondary-subtle h-100 d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon">
                        <i className="bx fs-4 bx-category ms-2"></i>
                    </div>
                    <div className="menu-title"> Gestion Ventes</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i> Ventes du jour
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/categories'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Les Ventes
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products/add' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Vents/Conteneurs
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon">
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon">

                        <i className="bx fs-4 bx-category ms-2"></i>
                    </div>
                    <div className="menu-title">
                        Fournisseurs
                    </div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/fournisseurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Fornisseurs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/fournisseurs/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Solde Fournisseur
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/fournisseurs/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon" hidden>
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title">Clients</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/fournisseurs' >
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Mes Clients
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/fournisseurs/solde'>
                            <i className="bx fs-4 bx-right-arrow-alt">
                            </i>Solde Client
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to='/fournisseurs/factures'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Factures non payees
                        </NavLink>
                    </li>
                </ul>
            </li>
            <li className="dropdwon" >
                <a className=" bg-secondary-subtle d-flex align-items-center p-2 gap-2 has-arrow" onClick={(e) => handleMenu(e)}>
                    <div className="parent-icon"> <i className="bx fs-4 bx-category ms-2"></i></div>
                    <div className="menu-title">Comptabilité</div>
                </a>
                <ul className="dropdown-list">
                    <li>
                        <NavLink to='/rapport-magasins'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Rapport magasins
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/rapport-comptable'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Rapport Comptable
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/admin/benefice'>
                            <i className="bx fs-4 bx-right-arrow-alt"></i>Bénefices
                        </NavLink>
                    </li>

                </ul>
            </li>

        </ul>
    );
}
export default MenuAdnin;