import { Loading, decryptData, defaulUrl } from "../components/const";
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";
import ModalAddProduct from "./ModalAddProduct";
import { useState } from "react";
import Swal from "sweetalert2";
import ModalEditeProduct from "./ModalEditeProduct";


const AddProduct = () => {
    const { data: produits, isloading: load } = UseFetch('produits/liste')
    let [isLoading, setIsLoading] = useState(false);
    const [selectedProduct, SetselectedProduct] = useState(null)
    const handleDelete = (id) => {
        Swal.fire({
            title: "Confirmez votre opération",
            text: 'Souhaitez-vous vraiment ce produit?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "green"
        }).then((rep) => {
            if (rep.isConfirmed) {
                setIsLoading(true)
                fetch(`${defaulUrl}produits/delete/${id}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
                    }
                }).then((response) => {
                    return response.json();
                }).then(data => {
                    Swal.fire({
                        title: "Succès",
                        text: data.message,
                        icon: 'success',
                        timer: 5000
                    })
                    setIsLoading(false)
                })
            }
        })
    }
    return (
        <div className="Prdouit container-fluid ">
            <div className="row my-2">
                <div className="col-lg-8 h2 text-primary">
                    GESTION DES PRODUITS
                </div>
                <div className="col-lg-4 text-end">
                    <button type="button" className="btn btn-primary " data-bs-toggle="modal" data-bs-target="#exampleModal" >Nouveau produit</button>
                </div>
            </div>
            {load ? <Loading /> : produits?.length ? <div className="row ">
                <div className="row my-2 justify-content-end">
                    <div className="col-lg-4 text-end">
                        <SearchField plage='plage' conteneur='tr' critere='produit' />
                    </div>
                    {isLoading && <Loading />}
                    <div className="table-responsive">
                        <table className="table table-white shadow table-bordered table-hover table-striped border-secondary">
                            <thead className="table-primary border-secondary">
                                <tr >
                                    <th hidden>#ID</th>
                                    <th>N°</th>
                                    <th>Produit</th>
                                    <th>Date creation</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {
                                    produits.map((p, i) => (
                                        <tr key={p.id} className="tr">
                                            <td hidden>{p.id}</td>
                                            <td>{i + 1}</td>
                                            <td className="produit">{p.produit}</td>
                                            <td>{new Date(p.created_at).toLocaleDateString()}</td>
                                            <td className="text-end">
                                                <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#editeProductModal" onClick={() => SetselectedProduct(p)}><i className="bx bx-edit" ></i></button>
                                                <button className="btn btn-danger m-1" onClick={() => handleDelete(p.id)}><i className="bx bx-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
                : <div className="p-2 row justify-content-center bg-danger-subtle text-danger fs-3">
                    Aucun produit trouvé!
                </div>
            }
            {/* Modal */}
            <ModalAddProduct />
            <ModalEditeProduct produit={selectedProduct} />
            {/* Fin modal */}
        </div>
    );
}

export default AddProduct;