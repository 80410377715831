
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";
import { decryptData, defaulUrl, Loading } from "../components/const";
import { useEffect, useState } from "react";
import FacturesByFournisseur from "./FacturesByFournisseurs";
const Solde = () => {
    const { data: fournisseurs, isloading } = UseFetch('fournisseurs/solde');
    const [totalDette, setTotalDette] = useState(0);
    const [facturesByIdFourn, setFacturesByIdFourn] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const handleShowFactureByFourn = (e, id) => {
        setIsLoading(true)
        e.preventDefault()

        fetch(`${defaulUrl}fournisseurs/${id}/factures`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setFacturesByIdFourn(data)
        })
    }
    useEffect(() => {
        setTotalDette(fournisseurs?.reduce((provious, current) => { return provious + (+current.reste) }, 0))
    }, [fournisseurs])
    return (
        <div className="Solde">
            <div className="row my-2">
                <div className="col-lg-6 h2 text-primary">
                    SOLDE DES FOURNISSEURS
                </div>

            </div>
            {
                isloading ? <Loading /> : fournisseurs?.length ?

                    <div className="row p-2">
                        <div className="row justify-content-end">
                            <div className="col-lg-6 text-end">
                                <SearchField plage='plage' conteneur='tr' critere='fourn' />
                            </div>
                        </div>
                        <div className="table-responsive p-2">
                            <table className="table table-hover table-striped table-bordered  border-primary">
                                <thead className="table-primary border-primary">
                                    <tr>
                                        <th>N°</th>
                                        <th>PRENOMS ET NOM</th>
                                        <th>TELEPHONE</th>
                                        <th>ADRESSE</th>
                                        <th>SOLDE</th>
                                        <th className="text-end">ACTIONS</th>
                                    </tr>

                                </thead>
                                <tbody className="plage">
                                    {
                                        fournisseurs.map((fourn, index) => (
                                            <tr key={index} className="tr">
                                                <td>{index + 1}</td>
                                                <td className="fourn">{`${fourn.prenoms} ${fourn.nom}`}</td>
                                                <td>{fourn.telephone}</td>
                                                <td>{fourn.adresse}</td>
                                                <td>{(+fourn.reste).toLocaleString()}{fourn.devise}</td>
                                                <td className="text-end">
                                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success" onClick={(e) => handleShowFactureByFourn(e, fourn.id)}>FACTURE NON REMBOURSEES</button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="fw-bold fs-3 text-center bg-danger-subtle" hidden>
                                        <td colSpan={4}>TOTAL DETTE</td>
                                        <td >{totalDette?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    :
                    <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-3">Aucun fournisseur trouvé!</div>
            }

            <FacturesByFournisseur data={facturesByIdFourn} isloading={isLoading} />
        </div>

    );
}

export default Solde;