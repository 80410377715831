import { useEffect, useState } from "react";
import { Loading } from "../components/const";

const DetailConteneur = ({ isLoading, detailproduct }) => {
    const [totalqte, setTotalQte] = useState(0);
    const [totaprix, setTotalPrix] = useState(0);
    const [totalmontant, setTotalMontant] = useState(0);

    useEffect(() => {
        if (detailproduct?.length) {
            setTotalQte(detailproduct.reduce((previous, current) => { return previous + current.quantite }, 0));
            setTotalPrix(detailproduct.reduce((previous, current) => { return previous + current.prix }, 0));
            setTotalMontant(detailproduct.reduce((previous, current) => { return previous + current.montant }, 0));
        }
    }, [detailproduct])
    return (
        <div className="AddModal">
            <div className="modal fade" id="detailConteneur" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Liste des produit du conteneur</h1>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">

                            <div className="row p-2 table-responsive">
                                {isLoading ? <Loading /> : <table className="table table-stripped table-bordered">
                                    <thead className="bg-secondary-subtle border-secondary text-center">
                                        <tr>
                                            <th>N°</th>
                                            <th>Produit</th>
                                            <th>Prix Unitaire</th>
                                            <th>quantite</th>
                                            <th>Montant</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            detailproduct.map((prod, index) => (
                                                <tr key={index + 1}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>{prod.produit}</td>
                                                    <td>{`${prod.prix.toLocaleString()} GNF`}</td>
                                                    <td>{prod.quantite}</td>
                                                    <td>{`${prod.montant.toLocaleString()} GNF`}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr className="bg-secondary-subtle">
                                            <td colSpan={3} className="text-center fs-3 fw-bold">Totaux</td>
                                            <td className="fw-bold">{totalqte}</td>
                                            {/* <td className="fw-bold">{totaprix.toLocaleString()} GNF</td> */}
                                            <td className="fw-bold">{totalmontant.toLocaleString()} GNF</td>
                                        </tr>
                                    </tbody>

                                </table>
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default DetailConteneur;