import { useEffect, useState } from "react";
import { decryptData, Loading } from "../components/const";
import ModalRemboursementFournisseur from "../Comptability/ModalRemboursementFournisseur";

const FacturesByFournisseur = ({ data, isloading }) => {
    const user = decryptData('USER');
    const [totalmontant, setTotalMontant] = useState(0);
    const [totapaye, setTotalPaye] = useState(0);
    const [totalreste, setTotalReste] = useState(0);
    const [selectedFacture, setFacture] = useState(null);
    useEffect(() => {
        if (data?.length) {
            setTotalMontant(data.reduce((previous, current) => { return previous + current.montant }, 0));
            setTotalPaye(data.reduce((previous, current) => { return previous + current.paye }, 0));
            setTotalReste(data.reduce((previous, current) => { return previous + current.reste }, 0));
        }
    }, [data])
    return (
        <>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-xl ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Factures impayées</h1>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            {isloading ? <Loading /> :
                                <div className="row p-2 table-responsive">

                                    <table className="table table-stripped table-bordered border-primary">
                                        <thead className="border-primary bg-primary-subtle text-center">
                                            <tr>
                                                <th>N°</th>
                                                <th>NUM-FACTURE</th>
                                                <th>MONTANT FACT</th>
                                                <th>MONTANT PAYE</th>
                                                <th>RESTE A PAYER</th>
                                                {user.droit === 'Comptable' && <th className="text-end">ACTIONS</th>}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                data?.map((fact, index) => (
                                                    <tr key={index + 1}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{fact.numero} </td>
                                                        <td>{fact.montant.toLocaleString()} {fact.devise}</td>
                                                        <td>{`${fact.paye.toLocaleString()} ${fact.devise}`}</td>
                                                        <td>{`${fact.reste.toLocaleString()} ${fact.devise}`}</td>
                                                        {user.droit === 'Comptable' &&
                                                            <td className="text-end">
                                                                <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalRemboursementFournisseur" onClick={() => {
                                                                    setFacture(fact)
                                                                }
                                                                }><i className="bx bx-dollar-circle"></i></button>
                                                            </td>
                                                        }

                                                    </tr>
                                                ))
                                            }
                                            <tr className="bg-secondary-subtle" >
                                                <td colSpan={2} className="text-center fs-3 fw-bold">Totaux</td>
                                                <td className="fw-bold">{totalmontant.toLocaleString()} </td>
                                                <td className="fw-bold">{totapaye.toLocaleString()} </td>
                                                <td className="fw-bold">{totalreste.toLocaleString()} </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            }
                        </div>

                    </div>
                </div>

            </div>

            <ModalRemboursementFournisseur facture={selectedFacture} table='conteneur' />
        </>


    );
}

export default FacturesByFournisseur;