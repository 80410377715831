import { useNavigate } from "react-router-dom";
import Select from "react-select";
import UseFetch from '../components/UseFetch'
import { useEffect, useState } from "react";
import { Loading, decryptData, defaulUrl, formatMoney, logout, onfocus, token } from "../components/const";
import Swal from "sweetalert2";
const AddApprovision = () => {
    const { data: magasins, isloading } = UseFetch('magasins/liste');
    let { data: conteneurs, isloading: load } = UseFetch('conteneurs/liste');
    let [magasin, setMagasin] = useState(null);
    let [conteneur, setConteneur] = useState(null);
    let [continfo, setContInfo] = useState(null);
    let [maginfo, setMagInfo] = useState(null);
    let [isLoading, setIsLoading] = useState(false)
    let [products, setProducts] = useState(null);
    const [totalVente, setTotalVente] = useState(0);
    const [totalQte, setTotalQte] = useState(0);
    const navigate = useNavigate();
    const handleCancel = () => {
        setContInfo(null);
        setMagInfo(null)
        setMagasin(null);
        setConteneur(null);
        setProducts(null);
    }
    const handleTrieConteneur = () => {
        if (conteneurs) {
            conteneurs = conteneurs.filter(cont => +cont.statut === 0)
        }
    }
    const handleDetail = (id) => {
        if (!conteneur) {
            Swal.fire({
                title: "Aucun conteneur choisi",
                text: "Choisissez un conteneur pour cet approvisionnement!",
                icon: "error",
            })
            return
        }
        setIsLoading(true)
        fetch(`${defaulUrl}stocks/liste/${+id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {

            setIsLoading(false)
            setProducts(data);
        })
    }
    const handleSave = () => {
        let status = 0;
        let isvalide = -1;
        if (!maginfo) {
            Swal.fire({
                title: "Magasin non selectionné!",
                text: "Veuillez selectionner le magasin à approvisionner",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        products.forEach((prod) => {
            if (!prod.pvu || prod.pvu < 1) {
                isvalide = products.indexOf(prod);
            }
        })
        if (isvalide >= 0) {
            Swal.fire({
                title: "Erreur de saisie!",
                text: "Vériez le prix  sur la ligne " + (isvalide + 1),
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        setIsLoading(true);
        fetch(`${defaulUrl}approvisions/add`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: JSON.stringify({
                'approvision': { 'idmagasin': magasin.value, 'idconteneur': conteneur.value },
                'produits': products
            })
        }).then(response => {
            status = response.status;
            return response.json();
        }).then(data => {
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                });
                handleCancel();
                navigate('/approvisions')
            }
            setIsLoading(false)
        })
    }
    useEffect(() => {
        if (conteneur) {
            setContInfo(conteneurs.find(({ id }) => id === +conteneur.value));

        }
        if (magasin) {
            setMagInfo(magasins.find(({ id }) => id === +magasin.value));
        }
    }, [conteneur, magasin])

    useEffect(() => {
        setTotalQte(products?.reduce((previous, current) => { return previous + current.quantite }, 0))
    }, [products])
    useEffect(() => {
        setTotalVente(products?.reduce((previous, current) => { return previous + current.quantite * current.pvu }, 0))
    })

    const user = decryptData('USER');
    if (user?.droit !== 'Administrateur') {
        Swal.fire({
            title: 'Accès non autorisé',
            icon: "error"
        }).then(() => {
            navigate('/login');
        })
        return
    }
    handleTrieConteneur()
    return (
        <div className="AddApprovision">
            <div className="row my-2">
                <div className="col-lg-8 h3 text-primary">
                    APPROVISIONMER UN MAGASIN
                </div>

            </div>
            <div className="row">
                <div className="col-lg-6 form-group">
                    <label htmlFor="">Magasin</label>
                    {isloading ? <Select /> :
                        <Select defaultInputValue={magasin?.label} options={

                            magasins.map((m) => (
                                {
                                    value: m.id, label: m.mag
                                }
                            ))}
                            onChange={setMagasin}

                        />
                    }
                </div>
            </div>
            <div className="row">

                <label htmlFor="">Conteneurs</label>
                {load ? <Select /> :
                    <Select
                        defaultInputValue={conteneur?.label}
                        options={
                            conteneurs.map((cont) => (

                                {
                                    value: `${cont.id}`, label: `${cont.conteneur}`
                                }

                            ))
                        }
                        onChange={setConteneur}
                        onInputChange={() => setProducts(null)}

                    />
                }

                {continfo && <div className="row">
                    <div className="col-sm-6 my-1">
                        <div className="form-group">
                            <label htmlFor="">Montant facture en devise</label>
                            <input type="text" disabled className="form-control"
                                value={`${(+continfo.Prix).toLocaleString()} ${(continfo.devise)}`} />
                        </div>
                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="form-group">
                            <label htmlFor="">Montant facture en GNF</label>
                            <input type="text" disabled className="form-control"
                                value={`${(continfo.Prix * continfo.valeur_du_jour)?.toLocaleString()} GNF`} />
                        </div>
                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="form-group">
                            <label htmlFor="">Depense totale(GNF)</label>
                            <input type="text" disabled className="form-control"
                                value={`${(+continfo.depense).toLocaleString()} GNF`} />
                        </div>
                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="form-group">
                            <label htmlFor="">Montant Total(GNF)</label>
                            <input type="text" disabled className="form-control"
                                value={`${continfo.Montant.toLocaleString()} GNF`} />
                        </div>

                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="form-group">
                            <label htmlFor="">Montant d'achat(GNF)</label>
                            <input type="text" disabled className="form-control"
                                value={`${continfo.montant_achat.toLocaleString()} GNF`} />
                        </div>

                    </div>
                    <div className="col-sm-6 my-1">
                        <div className="form-group">
                            <label htmlFor="">Benefice(GNF)</label>
                            <input type="text" disabled className="form-control"
                                value={`${(continfo.montant_achat - continfo.Montant).toLocaleString()} GNF`} />
                        </div>
                        <div className="text-end">
                            <button className="btn btn-primary my-2 ms-auto"
                                onClick={() => handleDetail(conteneur.value)}>
                                Charger les produits
                            </button>
                        </div>
                    </div>

                </div>}

            </div>
            {isLoading && <Loading />}
            {
                products ? <div className="row table-responsive mt-2 shadow">
                    <table className="table table-striped table-hover table-bordered">
                        <thead className="table-dark border-white text-center">
                            <tr>
                                <th>N°</th>
                                <th>Produit</th>
                                <th>quantite</th>
                                <th>PAU</th>
                                <th className="text-end" >PVU(GNF) </th>
                            </tr>
                        </thead>

                        {products && <tbody>
                            {products.map((prod, index) => (
                                <tr key={index + 1}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>{prod.produit}</td>
                                    <td>{prod.quantite}</td>
                                    <td>{`${(+prod.prix).toLocaleString()} GNF`}</td>
                                    <td className="text-end">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control"
                                                placeholder="Prix de vente unitaire"
                                                onChange={(e) => prod.pvu = + e.target.value.replace(/\s/g, "")}
                                                onBlur={(e) => formatMoney(e)}
                                                onFocus={(e) => onfocus(e)}
                                            />
                                            <span className="input-group-text" id="basic-addon2">GNF</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            }
                            <tr>
                                <td colSpan={2} className="fs-3 fw-bold text-center">Totaux</td>
                                <td className="fw-bold">{totalQte}</td>
                                <td className="fw-bold">{continfo.montant_achat.toLocaleString()} GNF</td>
                                <td className="fw-bold">{totalVente?.toLocaleString()} GNF</td>

                            </tr>
                        </tbody>}

                    </table>
                    <div className="row my-2 justify-content-end">
                        <div className="col-lg-4 text-center">
                            <button className="btn btn-danger m-1" onClick={() => handleCancel()}>Annuler</button>
                            <button className="btn btn-success m-1" onClick={handleSave}>Enregistrer</button>
                        </div>
                    </div>
                </div> : <div className="row"></div>
            }
        </div>
    );
}

export default AddApprovision;