import { useEffect, useState } from "react";
import { decryptData, defaulUrl } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from '../components/UseFetch';
import Paiements from "./Paiements";
import PayerDette from "./ModalPayerDette";
const DetteInitiale = () => {
    const profile = decryptData('user_profile');
    const { data: dettes, isloading } = UseFetch(`dettes/${profile.idmagasin}`);
    const [totalDette, setTotalDette] = useState(0);
    const [totalPaye, setTotalPaye] = useState(0);
    const [totalReste, setTotalReste] = useState(0);
    const [selectedclient, setSelectedClient] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [listPaiement, setPaiements] = useState([]);

    const handleShowPaiements = (e, id) => {
        setIsLoading(true)
        e.preventDefault()
        fetch(`${defaulUrl}dettes/${id}/paiements`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setPaiements(data)
        })

    }


    useEffect(() => {
        setTotalDette(dettes?.reduce((previous, current) => { return previous + current.dette }, 0))
        setTotalPaye(dettes?.reduce((previous, current) => { return previous + current.paye }, 0))
        setTotalReste(dettes?.reduce((previous, current) => { return previous + current.reste }, 0))
    }, [dettes?.length])
    return (
        isloading ? <div className="loader"></div> : dettes?.length ? <div className="dette">
            <div className="row p-2 justify-content-between align-items-center">
                <div className="col-lg-4 text-primary h3">
                    Clients en dette
                </div>
                <div className="col-lg-4 text-end">
                    <SearchField plage='plage' conteneur='tr' critere='critere' placeholder='Rechercher un client' />
                </div>
                <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>CLIENT</th>
                                <th>TELEPHONE</th>
                                <th>MONTANT DETTE</th>
                                <th>PAYE</th>
                                <th>RESTE</th>
                                <th>DATE</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                dettes?.map((dette, ind) => (
                                    <tr className="tr align-middle" key={ind}>
                                        <td>{ind + 1}</td>
                                        <td className="critere">{dette.client}</td>
                                        <td>{dette?.telephone}</td>
                                        <td>{dette?.dette.toLocaleString()}</td>
                                        <td>{dette?.paye.toLocaleString()}</td>
                                        <td>{dette?.reste.toLocaleString()}</td>
                                        <td>{(new Date(dette.date)).toLocaleDateString()}</td>
                                        <td className="text-end">
                                            <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#modalverser" onClick={(e) => setSelectedClient(dette)}>
                                                <i className="bx bx-dollar-circle" ></i> </button>
                                            <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#paiement" onClick={(e) => handleShowPaiements(e, dette.id)}>
                                                <i className="bx bx-show"></i> </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className="fw-bold">
                                <td colSpan={3} className="text-center ">Totaux</td>
                                <td>{totalDette?.toLocaleString()} GNF</td>
                                <td>{totalPaye?.toLocaleString()} GNF</td>
                                <td>{totalReste?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Paiements paiements={listPaiement} isLoading={isLoading} />
            <PayerDette dette={selectedclient} />
        </div> : <div className="w-100 fs- p-2 text-center text-danger bg-danger-subtle"> Aucun client n'a une dette initiale</div>
    );
}

export default DetteInitiale;