import { NavLink } from "react-router-dom";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import { Loading, decryptData, defaulUrl } from "../components/const";
import { useState } from "react";
import Swal from "sweetalert2";
const Approvisions = () => {
    let { data: approvisions, isloading } = UseFetch('approvisions/liste/' + 0);
    const [isLoading, setIsLoading] = useState(false);
    const [etat, setEtat] = useState('tous');
    const user = decryptData('USER');
    const profile = decryptData('user_profile');
    const [isadmin, setIsAdmin] = useState(user.droit === "Administrateur" || user.droit === 'Comptable');
    let [date1, setDate1] = useState(null);
    let [date2, setDate2] = useState(null);
    const [trieapprov, setTrie] = useState(null);
    const [idappro, setIdAppro] = useState(null)

    if (approvisions) {
        approvisions = isadmin ? approvisions : approvisions?.filter(prod => +prod.idmag === +profile.idmagasin);
    }

    const handleTrie = (e) => {

        const rows = document.querySelector('.plage').querySelectorAll('tr');
        if (etat !== 'tous') {
            rows.forEach(row => {
                const rowtext = row.innerText.toLocaleLowerCase();
                if (!rowtext.includes(etat.toLocaleLowerCase())) {
                    row.setAttribute('hidden', 'true');
                } else {
                    row.removeAttribute('hidden');
                }
            })
        } else {
            rows.forEach(row => {
                row.removeAttribute('hidden');
            })
        }
    }
    const handleValider = (id, opration) => {
        Swal.fire({
            title: "Confirmez votre opération",
            text: 'Souhaitez-vous vraiment appliquer cette opération?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "green"
        }).then((rep) => {
            if (rep.isConfirmed) {
                isloading = true;
                fetch(`${defaulUrl}approvisions/${opration}/${id}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
                    }
                }).then((response) => {
                    return response.json();
                }).then(data => {
                    Swal.fire({
                        title: "Succès",
                        text: data.message,
                        icon: 'success',
                        timer: 5000
                    })
                })
            }
        })

    }
    const handleTriByDate = (e) => {

        if (!date1 || !date2) {

            Swal.fire({
                title: "Date(s) invalides!",
                text: "Choisissez de bonnes dates pour date1 et date 2 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return;
        }
        e.preventDefault();
        setTrie(approvisions.filter((appro) => appro.created_at >= date1 && appro.created_at <= date2 + 1));

    }
    approvisions = approvisions?.sort((a, b) => a.statut - b.statut)

    return (
        <div className="Approvisions container-fluid">
            <div className="row my-2">
                <div className="col-lg-8 h3 text-primary">
                    LISTE DES APPROVISIONNEMENTS
                </div>
                {isadmin &&
                    <div className="col-lg-4 text-end">
                        <NavLink to='/approvisions/add' type="button" className="btn btn-primary ">Nouv. Appro</NavLink>
                    </div>
                }
            </div>


            {
                isloading ? <Loading /> : approvisions?.length ?

                    <div className="row">
                        <div className="row">
                            <div className="row  align-items-center">
                                <div className="col-lg-5 d-flex gap-1">
                                    <div className="form-group  col-4">
                                        <input type="date" className="form-control" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                                    </div>
                                    <div className="form-group col-4 ">
                                        <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                                    </div>
                                    <button onClick={(e) => handleTriByDate(e)} type="submit" className="btn btn-primary col-2 align-self-end"><i className="bx bx-search" ></i></button>
                                </div>
                                <div className="col-lg-4 py-2">
                                    <div className="row align-items-center ga-2">
                                        <label htmlFor="" className="col-lg-4 fs-4">Trier par: </label>
                                        <select name="" id="" className="form-select col" onInput={(e) => setEtat(e.target.value)} onChange={(e) => handleTrie(e)}>
                                            <option value="tous">Tous</option>
                                            <option value="En attente">En attente</option>
                                            <option value="Validé">Validé</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 justify-content-end">
                                <div className="col-lg-3 py-2">
                                    <SearchField plage="plage" conteneur="conteneur" critere="critere" />
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-striped table-hover table-white table-bordered border-primary">
                                <thead className="table-primary border-primary">
                                    <tr>
                                        <th>N°</th>
                                        <th>NUM-APPRO</th>
                                        {isadmin && <th>MAGASIN</th>}
                                        <th>CONTENEUR</th>
                                        <th>DATE APPRO</th>
                                        <th>STATUT</th>
                                        <th className="text-end">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody className="plage">

                                    {
                                        trieapprov ? trieapprov.map((appro, index) => (
                                            <tr key={index + 1} className="conteneur">
                                                <td>{index + 1}</td>
                                                <td>{appro.numero}</td>
                                                {isadmin && <td>{appro.magasin}</td>}
                                                <td className="critere">{appro.conteneur}</td>
                                                <td>{new Date(appro.created_at).toLocaleDateString()}</td>
                                                <td >{appro.statut ? 'Validé' : 'En attente'}</td>
                                                <td className="text-end">
                                                    <NavLink to={`/approvisions/${appro.id}`} className="btn btn-primary m-1" onClick={() => setIdAppro(appro.id)}><i className="bx bx-show"></i></NavLink>

                                                    {(isadmin && appro.statut === 0) && <button className="btn btn-danger m-1" onClick={() => handleValider(appro.id, 'delete')}><i className="bx bx-trash"></i></button>}
                                                    {(!isadmin && appro.statut === 0) && <button className="btn btn-success m-1" onClick={() => handleValider(appro.id, 'valider')}>Valider</button>}
                                                </td>
                                            </tr>
                                        )) :
                                            approvisions.map((appro, index) => (
                                                <tr key={index + 1} className="conteneur">
                                                    <td>{index + 1}</td>
                                                    <td>{appro.numero}</td>
                                                    {isadmin && <td>{appro.magasin}</td>}
                                                    <td className="critere">{appro.conteneur}</td>
                                                    <td>{new Date(appro.created_at).toLocaleDateString()}</td>
                                                    <td >{appro.statut ? 'Validé' : 'En attente'}</td>
                                                    <td className="text-end">
                                                        <NavLink to={`/approvisions/${appro.id}`} className="btn btn-primary m-1" onClick={() => setIdAppro(appro.id)}><i className="bx bx-show"></i></NavLink>
                                                        {(isadmin && appro.statut === 0) && <button className="btn btn-danger m-1" onClick={() => handleValider(appro.id, 'delete')}><i className="bx bx-trash"></i></button>}
                                                        {(!isadmin && appro.statut === 0) && <button className="btn btn-success m-1" onClick={() => handleValider(appro.id, 'valider')}>Valider</button>}
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>


                    : <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-3">
                        Aucun approvisionnement trouvé!
                    </div>
            }
        </div>
    );
}
export default Approvisions;