import { useState } from "react";
import { useParams } from "react-router-dom";
import VenteJour from "./VenteJour";
import VenteParConteneur from "./venteParConteneur";
import Clients from "../Clients/Clients";
import SoldeClient from "../Clients/SoldeClient";
import FactureNonPayees from "../Clients/FacturesNonPayees";
import UseFetch from "../components/UseFetch";

const ListeVenteAdmin = () => {
    const { id } = useParams()
    const [parjour, setParJour] = useState(1)
    const [idmag, setIdMag] = useState(id);
    const { data: magasins, isloading } = UseFetch('magasins/liste');
    return (
        isloading ? <div className="loader"></div> : <div className="container-fluid mb-2">
            <div className="modal-footer p-0 justify-content-start">
                <button className="btn btn-outline-secondary m-2" onClick={() => setParJour(1)}>Vente du jour</button>
                <button className="btn btn-outline-secondary m-2" onClick={() => setParJour(2)}>Vente par conteneur</button>
                <button className="btn btn-outline-secondary m-2" onClick={() => setParJour(3)}>Clients</button>
                <button className="btn btn-outline-secondary m-2" onClick={() => setParJour(4)}>Clients en dette</button>
                <button className="btn btn-outline-secondary m-2" onClick={() => setParJour(5)}>Factures impayées</button>
                <div className="col-lg-2 ms-auto">
                    <label htmlFor="">Choisir un autre magasin</label>
                    <select name="" id="" className="ms-auto form-select" onChange={(e) => setIdMag(e.target.value)}>
                        <option value=""> Choisir un magasin</option>
                        {
                            magasins?.map((mag, ind) => (
                                <option value={mag.id} key={ind}>{mag.mag}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <hr className="hr p-0 my-1" />
            {(parjour === 1) && <VenteJour id={idmag} />}
            {(parjour === 2) && <VenteParConteneur id={idmag} />}
            {(parjour === 3) && <Clients id={idmag} />}
            {(parjour === 4) && <SoldeClient idmagasin={idmag} />}
            {(parjour === 5) && <FactureNonPayees idmagasin={idmag} />}
        </div>
    );
}

export default ListeVenteAdmin;