import { decryptData } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import { useState } from "react";
const StokMois = () => {
    const id = decryptData('user_profile').idmagasin
    let { data: stocks, isloading: load } = UseFetch(`mois/${2}`);
    const [stockBymois, setByMois] = useState(null);
    let mois = stocks?.map(p => p.mois)
    mois = mois?.filter((item, index) => mois.indexOf(item) === index);
    stocks = stocks?.filter(stock => +stock.idmagasin === +id)
    return (
        <div className="StockMois">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                    <select name="" id="" className="form-select" onChange={(e) => setByMois(
                        stocks.filter(stock => stock.mois === e.target.value)
                    )}>
                        <option value="">{load ? 'Chargement...' : 'Choisir un mois'}</option>
                        {
                            mois && mois.map((m, i) => (
                                <option key={i} value={m}>{m}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-lg-6">
                    <SearchField />
                </div>
                {
                    stockBymois ?
                        <div className="table-responsive p-2 ">
                            <table className="table table-white table-hover table-striped shadow table-bordered">
                                <thead className="table-primary">
                                    <th>N°</th>
                                    <th>PRODUIT</th>
                                    <th>P.U</th>
                                    <th>QTE INITIALE</th>
                                    <th>QTE APPRO</th>
                                    <th>QTE TOTALE</th>
                                    <th>QTE VENDU</th>
                                    <th>QTE RESTANTE</th>
                                    <th>QTE APPRO ATTENTE</th>
                                </thead>
                                <tbody>
                                    {
                                        stockBymois.map((stock, i) => (
                                            <tr key={stock.id}>
                                                <td>{i + 1}</td>
                                                <td>{stock.produit}</td>
                                                <td>{stock.pvu}</td>
                                                <td>{stock.qte_initiale}</td>
                                                <td>{stock.qte_appro}</td>
                                                <td>{stock.qte_initiale + stock.qte_appro}</td>
                                                <td>{stock.qte_vendu}</td>
                                                <td>{(stock.qte_initiale + stock.qte_appro) - stock.qte_vendu}</td>
                                                <td>{stock.qte_appro_attente}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        : <div className="row p-2 bg-danger-subtle justify-content-center">Aucun stock trouve</div>}
            </div>
        </div>
    );
}

export default StokMois;