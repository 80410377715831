import { useEffect, useState } from "react";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import ModalDepense from "./ModalDepense";

import { defaulUrl, decryptData } from "../components/const";
import Paiements from "../Clients/Paiements";

const Depenses = () => {
    let { data: conteneurs, isloading } = UseFetch('depenses');
    const [totalDepense, setTotalDepense] = useState(0);
    const [totalPaye, setTotalPaye] = useState(0);
    const [totalReste, setTotalReste] = useState(0);
    const [selectedConteneur, setSelectedConteneur] = useState(null);
    const [listPaiement, setPaiements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    conteneurs = conteneurs?.filter(cont => (cont.reste > 0));
    
    const handleShowPaiements = (e, id) => {
        setIsLoading(true)
        e.preventDefault()
        fetch(`${defaulUrl}conteneur/${id}/depenses`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setPaiements(data)
        })

    }

    useEffect(() => {
        if (conteneurs?.length) {
            setTotalDepense(conteneurs?.reduce((previous, current) => { return previous + current.depense }, 0));
            setTotalPaye(conteneurs?.reduce((previous, current) => { return previous + current.paye }, 0));
            setTotalReste(conteneurs?.reduce((previous, current) => { return previous + current.reste }, 0));
        }
    }, [conteneurs])
    return (
        isloading ? <div className="loader"></div> : conteneurs?.length ?
            <div className="depense">
                <div className="row justify-content-end align-items-center">
                    <div className="col-lg-6 text-primary h4">
                        Gestion des dépenses sur conteneurs
                    </div>
                    <div className="col-lg-6 text-end">
                        <SearchField plage='plage' conteneur='tr' critere='cont' placeholder='Rechercher par conteneur' />
                    </div>
                </div>
                <div className="table-responsive p-2">
                    <table className="table table-hover table-striped table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <td>N°CONT</td>
                                <td>CONTENEUR</td>
                                <td>FOUNISSEUR</td>
                                <td>MONTANT DEPENSE</td>
                                <td>MONTANT PAYE</td>
                                <td>RESTE A PAYE</td>
                                <td className="text-end">ACTIONS</td>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                conteneurs?.map((cont, ind) => (
                                    <tr key={ind} className="tr">
                                        <td>{cont.numero}</td>
                                        <td className="cont">{cont.conteneur}</td>
                                        <td>{cont.fournisseur}</td>
                                        <td>{cont.depense.toLocaleString()}</td>
                                        <td>{cont.paye.toLocaleString()}</td>
                                        <td>{cont.reste.toLocaleString()}</td>
                                        <td className="text-end">
                                            <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#modalDepense" onClick={() => setSelectedConteneur(cont)}><i className="bx bx-dollar-circle"></i></button>
                                            <button className="btn btn-primary m-1" onClick={(e) => handleShowPaiements(e, cont.id)} data-bs-toggle="modal" data-bs-target="#paiement"><i className="bx bx-show" ></i></button>
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className="fw-bold">
                                <td colSpan={3} className="text-center fs-4">Totaux</td>
                                <td>{totalDepense.toLocaleString()} GNF</td>
                                <td>{totalPaye.toLocaleString()} GNF</td>
                                <td>{totalReste.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <ModalDepense conteneur={selectedConteneur} />
                <Paiements paiements={listPaiement} isLoading={isLoading} />
            </div> : <div className="w-100 text-center text-danger p-2 fs-3 bg-danger-subtle">
                Aucune dépense en attente!
            </div>

    );
}

export default Depenses;