import { NavLink } from "react-router-dom";
import { decryptData, Loading, defaulUrl } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import ModalAddClient from "./ModalAddClient";
import Versement from "./Versement";
import { useEffect, useState } from "react";

const Clients = ({ id }) => {
    const user = decryptData('USER')
    let idmagasin = 0;
    const isMagasin = user.droit === 'Magasin';
    const [isLoading, setIsLoading] = useState(false);
    const [versements, setVersements] = useState([]);
    const [idcompte, setIdCompte] = useState(null)
    const [totalSolde, setToltalSolde] = useState(0);
    if (isMagasin) {
        idmagasin = decryptData('user_profile').idmagasin
    }

    if (id) {
        idmagasin = id;
    }
    const { data: clients, isloading } = UseFetch(`clients/${idmagasin}`);
    const handleDetail = (e, id) => {
        e.preventDefault()
        setIsLoading(true)

        fetch(`${defaulUrl}versements/${id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setVersements(data)
        })

    }

    useEffect(() => {
        setToltalSolde(clients?.reduce((previous, current) => { return previous + current.solde }, 0))
    }, [clients])

    return (
        isloading ? <Loading /> :
            <div className="row">
                <div className="row justify content between align-items-center">
                    <div className="col-lg-8 text-primary h3">Gestion des clients</div>
                    <div className="col-lg-4 text-end">
                        {user.droit === 'Magasin' && <div className="text-end p-3">
                            <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#modalAddClient'>Nouveau client</button>
                        </div>}
                    </div>
                </div>
                {
                    clients?.length ?
                        <div className="row">

                            <div className="row justify-content-end">
                                <div className="col-lg-4 text-end">
                                    <SearchField plage='plage' conteneur='tr' critere='critere' placeholder='Rechercher un client' />
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>N°</th>
                                            <th>CLIENT</th>
                                            <th>TELEPHONE</th>
                                            <th>ADRESSE</th>
                                            {/* <th>SOLDE</th> */}
                                            <th>DATE CREATION</th>
                                            {/* <th>SOLDE</th> */}
                                            {isMagasin && <th className="text-end">ACTIONS</th>}
                                        </tr>
                                    </thead>
                                    <tbody className="plage">
                                        {
                                            clients.map((client, ind) => (
                                                <tr key={ind} className="tr">
                                                    <td>{ind + 1}</td>
                                                    <td className="critere">{client.client}</td>
                                                    <td>{client.telephone}</td>
                                                    <td>{client.adresse}</td>

                                                    <td>{(new Date(client.date)).toLocaleDateString()}</td>
                                                    {isMagasin &&
                                                        <td className="text-end">

                                                            <NavLink to={`/mag/${idmagasin}/client/${client.id}/factures`} className="btn btn-primary m-1" title="Voir factures"><i className="bx bx-show"></i></NavLink>
                                                        </td>}
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>

                        </div> :
                        <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-3 mt-2">
                            Vous n'avez aucun client enregistré
                        </div>
                }
                <ModalAddClient />
                <Versement isLoading={isLoading} versements={versements} />
            </div>
    );
}

export default Clients;