import { useRef, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";


const Pays = () => {
    let [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    let status = 0;
    const { data: pays, isloading } = UseFetch('pays/liste');
    const [ondelete, setOndelete] = useState(false)
    const ref = useRef()
    const handleSubmite = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let formData = new FormData(e.target);
        fetch(`${defaulUrl}pays/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            if (status === 200) {
                setIsLoading(false);
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })

                setErrors({});
                ref.current.click()
            } else {
                setErrors(data.errors)
            }
        })
    }

    const handleDelete = (e, id) => {
        e.preventDefault();
        setOndelete(true)
        Swal.fire({
            title: "Suppression",
            text: "Vous allez supprimer ce pays?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "red",
            cancelButtonColor: "blue"
        }).then((resp) => {
            if (resp.isConfirmed) {
                setIsLoading(true)
                fetch(`${defaulUrl}pays/delete/${id}`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + decryptData('ACCESS_TOKEN'),
                        Accept: 'application/json'
                    }
                }).then((response) => {
                    status = response.status;
                    return response.json();
                }).then((data) => {
                    if (status === 200) {
                        Swal.fire({
                            title: "Suppression de pays",
                            text: data.message,
                            icon: "success"
                        }).then(() => {
                            setIsLoading(false)
                            setOndelete(false)
                        });


                    }
                })
            }
        })
    }

    return (
        <div className="Pays">
            <div className="row p-2 my-2">
                <div className="row "><h3 className="text-primary text-end">GESTION DES PAYS</h3></div>
            </div>
            <div className="row justify-content-end">
                <div className="col-lg-3 text-end">
                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalPays">Ajouter un pays</button>
                </div>
            </div>

            <div className="row p-2 my-2">
                <div className="row justify-content-end">
                    <div className="col-lg-4">
                        <SearchField plage="pays" conteneur="tr" critere="p" />
                    </div>

                    {(ondelete && isLoading) && <span className="p-2 text-center"><Loading /></span>}
                    <div className="table-responsive mt-2">
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th hidden>#ID</th>
                                    <th>#ID</th>
                                    <th>Pays</th>
                                    <th>Date ajout</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="pays">
                                {isloading && <tr><td colSpan="5" className="p-3"> <Loading /></td></tr>}

                                {pays ? pays.map((pa, index) => (
                                    <tr key={pa.id} className="tr" >
                                        <td hidden> {pa.id}</td>
                                        <td> {index + 1}</td>
                                        <td className="p"> {pa.pays}</td>
                                        <td> {new Date(pa.created_at).toLocaleDateString()}</td>
                                        <td className="text-end">
                                            {/* <button className="btn btn-primary m-1" disabled>Editer</button>
                                            <button className="btn btn-info m-1" >Voir villes</button> */}
                                            <button className="btn btn-danger m-1" onClick={(e) => handleDelete(e, pa.id)}> <i className="bx bx-trash"></i> </button>
                                        </td>
                                    </tr>
                                )) : <tr><td rowSpan="4"> Aucun pays enregistré!</td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>



            <div className="modal fade " id="modalPays" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary-subtle">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter un pays</h1>
                            <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(e) => handleSubmite(e)} >
                            <div className="modal-body position-relative">
                                <div className="row justify-content-center">
                                    <div className="form-group">
                                        <label htmlFor="pays" className="fs-5">Nouveau Pays</label>
                                        <input type="text" className="form-control h-75" name="pays" id="pays" />
                                        {errors.pays && <span className="my-2 text-danger p-2"> {errors.pays}</span>}
                                    </div>
                                </div>
                                {isLoading && <span className="position-absolute"> <Loading /></span>}
                            </div>
                            <div className="modal-footer">
                                <a data-bs-dismiss="modal" ref={ref} className="btn btn-danger ">Annuler</a>
                                <button type="submit" className="btn btn-primary ">Enegistrer</button>

                            </div>
                        </form>


                    </div>

                </div>
            </div>
        </div>
    );
}

export default Pays;