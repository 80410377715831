import { useRef } from 'react';
import html2pdf from 'html2pdf.js';
import { useParams } from "react-router-dom";
import { decryptData, Loading } from '../components/const';
import UseFetch from '../components/UseFetch';
const PdfConverter = () => {
    const contentRef = useRef(null);
    const { idfacture } = useParams();
    const user = decryptData('USER');
    const profile = decryptData('user_profile');
    const { data, isloading } = UseFetch(`print/${idfacture}`)
    const facture = data?.facture;
    const produits = data?.produits;

    const convertToPdf = () => {
        const content = contentRef.current;
        const options = {
            filename: 'my-document.pdf',
            margin: 0,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: {
                unit: 'in',
                format: 'letter',
                orientation: 'portrait',
            },
        };
        html2pdf().set(options).from(content).outputPdf()
            .get('pdf')
            .then(function (pdfObj) {
                window.open(pdfObj.output("bloburl"), "F")
            })
    };

    return (
        isloading ? <Loading /> :
            <div className='shadow p-3 position-relative'>
                <div ref={contentRef} id='facture'>
                    <div className="text-center bg-primary-subtle rounded py-2 text-primary position-relative">
                        <img src="../images/store.png" alt="image"  className='logo' id='logo'/>
                        <h3 className='m-0'>FANSAN TEXTIL</h3>
                        <h4 className='m-0'>#ID{profile.idmagasin}/{profile.magasin}</h4>
                        <span>Adresse: {profile.ville}({profile.pays})</span><br />
                        <small>Contact: {(+profile.telephone).toLocaleString()}</small>
                    </div>
                    <div className="row justify-content-center mt-3 mx-3 ">
                        <div className="col-7">
                            <h4 className='text-muted border-bottom'>CLIENT</h4>
                            <h6>{facture.client}</h6>
                            <span>Tél: {facture.telephone}</span><br />
                            <span>Adresse: {facture.adresse}</span>

                        </div>

                        <div className="col-4 text-primary text-center">
                            <h4 className='text-muted border-bottom'>FACTURE</h4>
                            <div className="w-75 text-end">DATE: <span className="text-muted">{(new Date(facture.date)).toLocaleDateString()}</span></div>
                            <div className="w-75 text-end">N°: <span className="text-muted">{facture.numero}</span></div>
                        </div>
                    </div>
                    <div className="text-center p-1">
                        <div className='bg-secondary-subtle py-1 w-75 mx-auto'></div>
                    </div>
                    <div className='px-5 mt-3 mx-4'>
                        <table className='table table-striped '>
                            <thead className='table-primary'>
                                <tr>
                                    <th>N°</th>
                                    <th>DESIGNATION</th>
                                    <th>QTE</th>
                                    <th>PVU</th>
                                    <th>MONTANT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    produits.map((prod, ind) =>
                                        <tr key={ind} className='table-'>
                                            <td>{ind + 1}</td>
                                            <td>{prod.produit}</td>
                                            <td>{prod.quantite}</td>
                                            <td>{prod.pvu} GNF</td>
                                            <td>{prod.pvu * prod.quantite} GNF</td>
                                        </tr>
                                    )
                                }
                                <tr>
                                    <td colSpan={3} className='table-border border-0'></td>
                                    <td className='fw-bold fs-5 bg-secondary-subtle text-end border-1'>Total</td>
                                    <td className='fw-bold fs-5 bg-secondary-subtle text-center border-1'>{(+facture.total).toLocaleString()}GNF</td>
                                </tr>
                                <tr className='bg-white'>
                                    <td colSpan={3} className='table-border border-0 bg-white'></td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-end border-1'>REMISE</td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-center border-1'>{(+facture.remise).toLocaleString()}GNF</td>
                                </tr>
                                <tr className='bg-white'>
                                    <td colSpan={3} className='table-border border-0 bg-white'></td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-end  border-1'>NET A PAYER</td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-center border-1'>{(+facture.net).toLocaleString()}GNF</td>
                                </tr>
                                <tr className='bg-white'>
                                    <td colSpan={3} className='table-border border-0 bg-white'></td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-end  border-1'>MONTANT PAYE</td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-center  border-1'>{(+facture.paye).toLocaleString()}GNF</td>
                                </tr>
                                <tr className='bg-white'>
                                    <td colSpan={3} className='table-border border-0 bg-white'></td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-end  border-1'>RESTE A PAYER</td>
                                    <td className='fw-bold fs-6 bg-secondary-subtle text-center  border-1'>{(+facture.reste).toLocaleString()}GNF</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-4 p-2 text-center">
                            <span>{profile.ville}, le {(new Date()).toLocaleDateString()} </span><br />
                            <span className='fw-bold'>{profile.ville}, Le Gérant</span><br />
                            <h5 className='mt-5'>{`${user.firstname} ${user.lastname}`}</h5>

                        </div>
                    </div>
                </div>
                <button className='btn btn-danger btn-print' onClick={convertToPdf}><i className='bx bx-download'></i></button>
            </div>
    );
};

export default PdfConverter;