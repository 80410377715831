
import { decryptData, defaulUrl, Loading } from "../components/const";
import { useEffect, useState } from "react";
import UseFetch from '../components/UseFetch';
import Select from "react-select";
const VenteParConteneur = ({ id }) => {
    if (!id) {
        id = decryptData('user_profile').idmagasin;
    }
    let { data: conteneurs, isloading } = UseFetch(`approvisions/vendu/${id}`);
    const [isLoading, setIsLoading] = useState(false);
    const [ventePeriode, setVentesPeriode] = useState(null);
    const [totalqte, setTotalQte] = useState(0);
    const [totalmontant, setTotalMontant] = useState(0);
    const [conteneur, setConteneur] = useState(null);
    const [error, setError] = useState(null);
    const handleShowVentesConteneur = (idconteneur) => {
        if (!conteneur) {
            setError('Veuillez choisir un conteneur');
            return
        } else {
            setError(null)
        }
        setIsLoading(true);
        let status = 0;
        fetch(`${defaulUrl}ventes/conteneur/${idconteneur}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsLoading(false);
            if (status === 200) {
                da?.sort((a, b) => a.conteneur.localeCompare(b.conteneur))
                setVentesPeriode(da);
            }
        })
    }
    useEffect(() => {
        if (ventePeriode?.length) {
            setTotalQte(ventePeriode?.reduce((previous, current) => { return previous + current.quantite }, 0))
            setTotalMontant(ventePeriode?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
    }, [ventePeriode])
    return (
        <div className="container-fluid">

            <div className="row justify-content-between p-2 text-primary">
                <div className="col-lg-6 h3">
                    Liste des ventes par conteneur
                </div>
                <div className="col-lg-6 d-flex gap-1 align-items-center justify-content-end">

                    <div className="form-group col-lg-8">
                        <label htmlFor="">Choisir un conteneur</label>
                        {isloading ? <Select /> :
                            <Select
                                defaultInputValue={conteneur?.label}
                                options={
                                    conteneurs.map((cont) => (

                                        {
                                            value: `${cont.id}`, label: `${cont.conteneur}`
                                        }

                                    ))
                                }
                                onChange={setConteneur}
                            />
                        }
                        {error && <span className="text-danger">{error}</span>}
                    </div>
                    <button type="submit" className="btn btn-primary col-1 align-self-end" onClick={() => handleShowVentesConteneur(conteneur?.value)}><i className="bx bx-show" ></i></button>
                </div>
            </div>

            {isLoading ? <div className="loader"></div> :
                <div className="row">
                    {
                        (ventePeriode?.length) ? <div className="table-responsive ">
                            <table className="table  table-hover table-striped shadow table-bordered">
                                <thead className="table-dark border-white">
                                    <tr>

                                        <th>PRODUIT</th>
                                        <th>QTE</th>
                                        <th>PVU</th>
                                        <th>MONTANT</th>
                                        <th>N°FACTURE</th>
                                        <th>CLIENT</th>
                                        <th>DATE</th>
                                    </tr>
                                </thead>
                                <tbody className="plage">
                                    {
                                        ventePeriode?.map((prod, ind) => (
                                            <tr key={ind} className="tr">
                                                <td>{prod?.produit}</td>
                                                <td>{prod?.quantite}</td>
                                                <td>{prod?.pvu.toLocaleString()}</td>
                                                <td>{prod?.montant.toLocaleString()}</td>
                                                <td>{prod?.numero}</td>
                                                <td>{prod?.client}</td>
                                                <td>{new Date(prod?.created_at).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="fw-bold bg-secondary-subtle">
                                        <td className="text-center">Totaux</td>
                                        <td>{totalqte}</td>
                                        <td></td>
                                        <td>{totalmontant.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> : <div className="row justify-content-center fs-3 bg-danger-subtle text-danger p-2">Aucune vente effectuee</div>
                    }
                </div>
            }


        </div>


    );
}

export default VenteParConteneur;