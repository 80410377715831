import { useParams, NavLink } from "react-router-dom";
import { decryptData, Loading, defaulUrl } from "../components/const";
import UseFetch from "../components/UseFetch";
import { useState } from "react";
import DetailFactures from "./DetailFacture";
import ModalRemboursement from "../Fournisseurs/ModalRemboursement";
import Paiements from "./Paiements";
const ModalFacturesClient = () => {
    const user = decryptData('USER')
    const { idmag, id } = useParams();
    let { data, isloading } = UseFetch(`magasin/${idmag}/client/${id}/factures`)
    const client = data?.client;
    let factures = data?.factures;
    const totaux = data?.totaux;
    const [selectedFacture, setFacture] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [detailfacture, setDetailFacture] = useState([]);
    const [listPaiement, setPaiements] = useState([]);
    factures?.sort((a, b) => (b.reste - a.reste))
    const isMagasin = user.droit === 'Magasin';

    const handleSelectFacture = (fact) => {
        setFacture(fact)
    }
    const handleDetail = (e, id) => {
        setIsLoading(true)
        e.preventDefault()

        fetch(`${defaulUrl}factures/${id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setDetailFacture(data)
        })

    }
    const handleShowPaiements = (e, numero) => {
        setIsLoading(true)
        e.preventDefault()

        fetch(`${defaulUrl}paiements/${numero}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setPaiements(data)
        })

    }
    return (
        <div className="detail">

            <div className="modal-body py-2">

                {
                    isloading ? <Loading /> : factures?.length ?

                        <div className="row">
                            <h3 className="modal-title fs-5" id="exampleModalLabel">COMPTE CLIENT - <span className="text-primary">{client.client}</span></h3>
                            <div className="table-responsive mt-2">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>N°</th>
                                            <th>N°FACTURE</th>
                                            <th>MONTANT</th>
                                            <th>REMISE</th>
                                            <th>MONTANT NET</th>
                                            <th>PAYE</th>
                                            <th>RESTE</th>
                                            <th>DATE</th>
                                            <th className="text-end">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            factures.map((fact, ind) => (
                                                <tr key={ind}>
                                                    <td>{ind + 1}</td>
                                                    <td>{fact.numero}</td>
                                                    <td>{fact.total.toLocaleString()}</td>
                                                    <td>{(+fact.remise).toLocaleString()}</td>
                                                    <td>{(+fact.montant).toLocaleString()}</td>
                                                    <td>{(+fact.paye).toLocaleString()}</td>
                                                    <td>{(+fact.reste).toLocaleString()}</td>
                                                    <td>{new Date(fact.created_at).toLocaleDateString()}</td>
                                                    <td className="text-end">
                                                        {(isMagasin) &&
                                                            <NavLink to={`/print/${fact.id}`} className="btn btn-danger m-1" title="Imprimer en pdf">
                                                                <i className="bx bx-download"></i>
                                                            </NavLink>
                                                        }
                                                        {(isMagasin && fact.reste > 0) &&
                                                            <button data-bs-toggle="modal" data-bs-target="#modalRemboursement" className="btn btn-success m-1" title="Faire un encaissement" onClick={() => handleSelectFacture(fact)}>
                                                                <i className="bx bx-dollar-circle"></i>
                                                            </button>}
                                                        <button data-bs-toggle="modal" data-bs-target="#paiement" className="btn btn-primary m-1" title="Detail encaissements" onClick={(e) => handleShowPaiements(e, fact.numero)}>
                                                            <i className="bx bx-task"></i>
                                                        </button>
                                                        <button data-bs-toggle="modal" data-bs-target="#detail" className="btn btn-info m-1" title="Detail facture" onClick={(e) => handleDetail(e, fact.id)}>
                                                            <i className="bx bx-list-ol"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr className="table-secondary fw-bold " >
                                            <td colSpan={2} className=" text-center ">TOTAUX </td>
                                            <td>{(+totaux.total).toLocaleString()} </td>
                                            <td>{(+totaux.remise).toLocaleString()} </td>
                                            <td>{(+totaux.montant).toLocaleString()} </td>
                                            <td>{(+totaux.paye).toLocaleString()} </td>
                                            <td>{(+totaux.reste).toLocaleString()} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <div className="row p-2 text-danger bg-danger-subtle justify-content-center">Aucune facture pour ce client</div>
                }

            </div>




            {/* MODAL DETAIL FATURES */}

            <DetailFactures data={detailfacture} isLoading={isLoading} />
            <ModalRemboursement facture={selectedFacture}  table='factures' />
            <Paiements paiements={listPaiement} isLoading={isLoading} />
        </div>

    );
}

export default ModalFacturesClient;