import { decryptData, Loading } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";

const Fournisseurs = () => {
    const { data: fournisseurs, isloading } = UseFetch('fournisseurs/liste');
    const user = decryptData('USER');
    return (
        <div className="Fournsisseur">
            <div className="row my-2">
                <div className="col-lg-6 h2 text-primary">
                    LISTE DES FOURNISSEURS
                </div>

            </div>
            {
                isloading ? <Loading /> : fournisseurs?.length ?
                    <div className="row">
                        <div className="row justify-content-end">
                            <div className="col-lg-6 text-end">
                                <SearchField plage='plage' conteneur='tr' critere='fourn' />
                            </div>
                        </div>
                        <div className="table-responsive p-2">
                            <table className="table table-hover table-striped table-bordered border-primary">
                                <thead className="table-primary border-primary">
                                    <tr>
                                        <td>N°</td>
                                        <td>PRENOMS ET NOM</td>
                                        <td>TELEPHONE</td>
                                        <td>ADRESSE</td>
                                        <td>DATE AJOUT</td>
                                        {user.droit === 'Administrateur' && <td className="text-end">ACTIONS</td>}
                                    </tr>

                                </thead>
                                <tbody className="plage">
                                    {
                                        fournisseurs.map((fourn, index) => (
                                            <tr key={index} className="tr">
                                                <td>{index + 1}</td>
                                                <td className="fourn">{`${fourn.prenoms} ${fourn.nom}`}</td>
                                                <td>{fourn.telephone}</td>
                                                <td>{fourn.adresse}</td>
                                                <td>{new Date(fourn.created_at).toLocaleDateString()}</td>
                                                {user.droit === 'Administrateur' && <td className="text-end">
                                                    <button className="btn btn-primary">Editer</button>
                                                </td>}
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>

                    </div>

                    :
                    <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-3 fs-5">Aucun fournisseur trouvé!</div>
            }

        </div>
    );
}

export default Fournisseurs;