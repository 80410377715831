import { useRef, useState } from "react";
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";

const OperationsMagasin = ({ titre, type, droit, solde, isortie, versement }) => {
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [montant, setMontant] = useState(0);
    const [description, setDescription] = useState(null)
    const ref = useRef();
    const handleSave = (e) => {
        e.preventDefault();
        if (!montant) {
            setErrors({ "montant": "Veuillez saisir le montant" });
            return
        }
        let status = 0;
        if (isortie) {
            if (+montant > +solde) {
                setErrors({ "montant": "Le montant saisi est superieur a votre solde!" });
                return
            }
        }
        let url = 'operation/add'
        setIsLoading(true)
        const formData = new FormData();
        formData.set('montant', +montant);
        formData.set('description', description);
        formData.set('type', type)
        if (droit) {
            formData.append('droit', droit);
        }
        if (versement) {
            url = 'versement/add'
            formData.set('solde', +versement)
        }


        fetch(`${defaulUrl}${url}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    ref.current.click();
                })
                setErrors(null)
            } else {
                setErrors(data.errors);
            }
        })
    }
    return (
        <div className="modal fade " id="modalOprationMagasin" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{titre}</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <span className="text-primary bg-secondary-subtle px-3"></span>
                    <form onSubmit={(e) => handleSave(e)}>
                        <div className="modal-body position-relative">                        
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" defaultValue={montant}
                                        onChange={(e) => setMontant(e.target.value.replace(/\D/g, ""))}
                                        onBlur={(e) => formatMoney(e)}
                                        onFocus={(e) => onfocus(e)}
                                    />
                                    <span className="input-group-text" id="basic-addon2">GNF</span>
                                </div>
                                {errors?.montant &&
                                    <span className="text-danger py-2">{errors?.montant}</span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Description</label>
                                <textarea id="description" className="form-control" defaultValue={description} onChange={(e) => setDescription(e.target.value)}>
                                </textarea>
                                {errors?.description &&
                                    <span className="text-danger py-2">{errors?.description}</span>
                                }
                            </div>
                            {isLoading && <span className="position-absolute"> <Loading /></span>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="submite" className="btn btn-success">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default OperationsMagasin;