import { NavLink } from "react-router-dom";
import UseFetch from "../components/UseFetch";
import { decryptData, Loading } from "../components/const";
import SearchField from "../components/SeachField";
import { useState } from "react";
import AddMagasin from "./AddMagasin";
const Magasins = () => {
    const { data: magasins, isloading } = UseFetch('magasins/liste');
    const [id, setId] = useState(0);
    const user = decryptData('USER');
    const isAdmin = user.droit === 'Administrateur';
    return (
        <div className="Magasins">
            {isAdmin && <div className="row p-2 justify-content-end mt-2">
                <div className="col-lg-3 text-end"><button data-bs-toggle="modal" data-bs-target="#modalAdd" className="btn btn-primary px-3">Nouveau magasin</button></div>
            </div>}

            <div className="row ">
                {isloading ? <div className="row w-100 p-3 "><Loading /></div> : magasins?.length ?

                    <div className="row ">

                        <div className="row">
                            <div className="col-lg-6"> <h1 className="text-primary">Liste des magasins</h1></div>
                            <div className="col-lg-6">
                                <SearchField plage="plage" conteneur="col" critere='magasin-name' placeholder='Rechercher par magasin' />
                            </div>
                        </div>
                        {magasins.map((mag) => (
                            <div className="col-md-6 col-lg-4 " key={mag.id}>
                                <div className="card radius-10 border-start border-0 border-3 border-info card-magasin m-1">
                                    <div className="py-4 card-body">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <h4 className="mb-0 text-secondary">Code: #{mag.id}</h4>
                                                <h4 className="mb-0 text-secondary magasin-name">{mag.mag} </h4>
                                                <h5 className="my-1  text-info ">{mag.v}({mag.pays})</h5>
                                            </div>

                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <NavLink to={`/magasins/${mag.id}/stocks`} className="btn btn-primary radius-5 mx-1" onClick={() => setId(mag.id)}>Voir stock</NavLink>
                                            <NavLink to={`/ventes/mag/${mag.id}`} className="btn btn-success radius-5 mx-1" onClick={() => setId(mag.id)}>Voir ventes</NavLink>
                                            <button hidden className="btn btn-warning text-success radius-5 mx-1 btn-note" data-bs-toggle="modal" data-bs-target="#modalNoter" onClick={() => setId(mag.id)}>Noter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> :



                    <div className="row justify-content-center fs-4 bg-danger-subtle text-danger w-100 p-2">Aucun Magasin trouvé !</div>
                }
            </div>




            <div className="modal fade" id="modalNoter" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Noter un magasin #{id}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form >

                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="" className="fs-5">Votre Appréciation</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Annuler</button>
                                <button type="button" className="btn btn-success">Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <AddMagasin />
        </div>
    );
}
export default Magasins;