import { useParams, NavLink } from "react-router-dom";
import SearchField from "../components/SeachField";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
const StockMagasin = ({ idmag }) => {
    let { id } = useParams();
    if (idmag) {
        id = idmag
    }
    const { data: conteneurs, isloading } = UseFetch(`approvisions/liste/${id}`);
    const { data: stocks, isloading: loanding } = UseFetch(`mag/${id}/stocks`);
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
    let [date1, setDate1] = useState(null);
    let [date2, setDate2] = useState(null);
    const [stockMois, setByMonth] = useState(null)

    const [totalQte, setTotalQte] = useState(0);
    const [totalPVU, setTotalPVU] = useState(0);
    const [totalMontant, setTotalMontant] = useState(0);
    const [qteVendu, setQteVendu] = useState(0);
    const [MontantVendu, setMontantVendu] = useState(0);
    const [qteReste, setQteReste] = useState(0);
    const [montantReste, setMontantReste] = useState(0);

    const stockAttente = conteneurs ? conteneurs.filter(cont => cont.statut === 0) : null
    const ByMonthstockMois = conteneurs ? conteneurs.filter(cont => cont.statut === 1) : null
    let infoConteneur = stocks?.map(p => ({ 'numero': p.numero, 'conteneur': p.conteneur }))
    infoConteneur = [...new Map(infoConteneur?.map(o => [o.numero, o])).values()]//elimine les doublons
    const handleTriByDate = (e) => {
        if (!date1 || !date2) {

            Swal.fire({
                title: "Date(s) invalides!",
                text: "Choisissez de bonnes dates pour date1 et date 2 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return;
        }
        if (date1 > date2) {

            Swal.fire({
                title: "Date(s) invalides!",
                text: "La  date 2 doit être supérieure a la date 1 ",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return;
        }
        e.preventDefault();
        setByMonth(ByMonthstockMois.filter((appro) => appro.updated_at >= date1 && appro.updated_at <= date2 + 1));
        console.log(ByMonthstockMois)
    }
    useEffect(() => {
        if (stocks) {
            setTotalQte(stocks?.reduce((previous, current) => { return previous + (+current.quantite) }, 0));
            setTotalPVU(stocks?.reduce((previous, current) => { return previous + (+current.pvu) }, 0));
            setTotalMontant(stocks?.reduce((previous, current) => { return previous + (+current.quantite * +current.pvu) }, 0));

            setQteVendu(stocks?.reduce((previous, current) => { return previous + (+current.vendu) }, 0));
            setMontantVendu(stocks?.reduce((previous, current) => { return previous + (+current.vendu * +current.pvu) }, 0));
            setQteReste(stocks?.reduce((previous, current) => { return previous + (+current.stock) }, 0));
            setMontantReste(stocks?.reduce((previous, current) => { return previous + (+current.stock * +current.pvu) }, 0));
        }
    }, [stocks]);
    return (
        isloading ? <Loading /> :
            <div className="produit">
                <div className="row">
                    <div className="col-lg-6 h3 text-primary"> SITUATION DU STOCK DU MAGASIN #{id}</div>
                </div>
                <div className="accordion accordion-flush" id="stock">
                    <div className="accordion-item my-3 shadow">
                        <h2 className="accordion-header ">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#stock-actuel" aria-expanded="false" aria-controls="stock-actuel">
                                <span className="fs-4"> Stock Actuel</span>
                            </button>
                        </h2>
                        <div id="stock-actuel" className="accordion-collapse collapse" data-bs-parent="#stock">
                            <div className="accordion-body bg-transparent">
                                <div className="row p-0">
                                    {stocks?.length  ?
                                        <div className="table-responsive px-0">

                                            <table className="table table-white table-bordered table-hover table-striped">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th className="text-center align-middle" width="8%">NUMERO</th>
                                                        <th className="text-center align-middle" width="10%">CONTENEUR</th>
                                                        <th className="text-center pb-0 px-0 fs-4">
                                                            LISTE DES PRODUITS
                                                            <table className="table table-bordered table-secondary m-0 px-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="4%" className="align-middle">N°</th>
                                                                        <th width="25%" className="align-middle">PRODUIT</th>
                                                                        <th width="8%" className="align-middle">QTE APPRO</th>
                                                                        <th width="10%" className="align-middle bg-success-subtle">PVU</th>
                                                                        <th width="12%" className="align-middle">MONTANT</th>
                                                                        <th width="8%" className="align-middle">QTE VENDU</th>
                                                                        <th width="12%" className="align-middle">MONTANT VENDU</th>
                                                                        <th width="8%" className="align-middle">QTE RESTE</th>
                                                                        <th width="12%" className="align-middle">MONTANT RESTANT</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        infoConteneur.map((info, index) => (
                                                            <tr key={index} className="border-2 border-info">
                                                                <td className="align-middle text-center ">{info.numero}</td>
                                                                <td className="align-middle text-center ">{info.conteneur}</td>
                                                                <td className="p-0">
                                                                    <table className="table table-striped table-hover table-bordered p-0 m-0">

                                                                        <tbody className="p-0">

                                                                            {
                                                                                stocks.map((stock, i) => (
                                                                                    (stock.numero === info.numero) && <tr key={i}>
                                                                                        <td width="4%" >{i + 1}  </td>
                                                                                        <td width="25%" >{stock.produit}</td>
                                                                                        <td width="8%">{stock.quantite}</td>
                                                                                        <td width="10%" className="bg-success-subtle">{(+stock.pvu).toLocaleString()}</td>
                                                                                        <td width="12%" >{(stock.quantite * stock.pvu).toLocaleString()}</td>
                                                                                        <td width="8%">{stock.vendu}</td>
                                                                                        <td width="12%">{(stock.vendu * stock.pvu).toLocaleString()}</td>
                                                                                        <td width="8%">{stock.stock}</td>
                                                                                        <td width="12%">{(stock.stock * stock.pvu).toLocaleString()}</td>

                                                                                    </tr>

                                                                                ))
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr>
                                                        <td colSpan={2} className="bg-white border-none"></td>
                                                        <td>
                                                            <table className="table borederless">
                                                                <tbody>
                                                                    <tr className="border-none text-center fs-3 fw-bold">
                                                                        <td width="30%">TOTAUX</td>
                                                                        <td width="8%"> {totalQte}</td>
                                                                        <td width="10%"></td>
                                                                        <td width="12%">{totalMontant?.toLocaleString()} GNF</td>
                                                                        <td width="8%">{qteVendu}</td>
                                                                        <td width="12%">{MontantVendu?.toLocaleString()} GNF</td>
                                                                        <td width="8%">{qteReste}</td>
                                                                        <td width="12%">{montantReste?.toLocaleString()} GNF</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>

                                        </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock disponible pour le moment</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item my-3 shadow">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#stock-attente" aria-expanded="false" aria-controls="stock-attente">
                                <span className="fs-4"> Stock du mois</span>
                            </button>
                        </h2>
                        <div id="stock-attente" className="accordion-collapse collapse" data-bs-parent="#stock">
                            <div className="accordion-body">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-lg-5 d-flex gap-1">
                                        <div className="form-group  col-4">
                                            <input type="date" className="form-control" id="date1" name="date1" defaultValue={firstDay} onChange={(e) => setDate1(e.target.value)} />
                                        </div>
                                        <div className="form-group col-4 ">
                                            <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                                        </div>
                                        <button onClick={(e) => handleTriByDate(e)} type="submit" className="btn btn-primary col-2 align-self-end"><i className="bx bx-search" ></i></button>
                                    </div>
                                    {stockMois ?
                                        stockMois?.length  ?
                                            <div className="table-responsive p-2 ">
                                                <table className="table table-white table-hover table-striped table-bordered">
                                                    <thead className="table-primary">

                                                        <tr>
                                                            <th>N°</th>
                                                            <th>NUM-CONTENEUR</th>
                                                            <th>CONTENEUR</th>
                                                            <th>STATUT</th>
                                                            <th>DATE APPRO</th>
                                                            <th>DATE VALIDATION</th>
                                                            <th className="text-end">ACTIONS</th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {
                                                            stockMois.map((cont, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{cont.numero}</td>
                                                                    <td>{cont.conteneur}</td>
                                                                    <td>{cont.statut ? 'Validé' : 'En attente'}</td>
                                                                    <td>{new Date(cont.created_at).toLocaleDateString()}</td>
                                                                    <td>{new Date(cont.updated_at).toLocaleDateString()}</td>
                                                                    <td className="text-end">
                                                                        <NavLink to={`/approvisions/${cont.id}`} className="btn btn-primary m-1">Voir stock</NavLink>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div> : <div className="row p-2 mt-2  mx-1 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock Enregistré pour cette periode</div>
                                        : <div className="row p-2 mt-2  mx-1 justify-content-center bg-info-subtle text-primary fs-4">Choisissez une periode</div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item my-3 shadow">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                <span className="fs-4"> Stock en attente</span>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#stock">
                            <div className="accordion-body">
                                <div className="row justify-content-end">
                                    <div className="col-lg-6">
                                        <SearchField />
                                    </div>
                                </div>

                                <div className="row p-2">
                                    {
                                        stockAttente?.length ? <div className="table-responsive">

                                            <table className="table table-white table-bordered table-hover table-striped table-bordered">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>N°</th>
                                                        <th>NUM-CONTENEUR</th>
                                                        <th>CONTENEUR</th>
                                                        <th>STATUT</th>
                                                        <th>DATE APPRO</th>
                                                        <th className="text-end">ACTIONS</th>
                                                    </tr>

                                                </thead>

                                                <tbody>
                                                    {
                                                        stockAttente.map((cont, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{cont.numero}</td>
                                                                <td>{cont.conteneur}</td>
                                                                <td>{'En attente'}</td>
                                                                <td>{new Date(cont.created_at).toLocaleDateString()}</td>
                                                                <td className="text-end">
                                                                    <NavLink to={`/approvisions/${cont.id}`} className="btn btn-primary m-1">Voir stock</NavLink>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>

                                            </table>

                                        </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-4">Aucun stock en attente</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
    );
}

export default StockMagasin;