import { useState, useEffect } from "react";
import { defaulUrl, Loading, decryptData, deleteLocalStorage, formatMoney, onfocus } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ModalAddFournisseur from "../Fournisseurs/ModalAddFournisseur";

const Addconteneur = () => {
    const { data: produits, isloading } = UseFetch('produits/liste')
    const { data: fournisseurs, isloading: loader } = UseFetch(`fournisseurs/liste`)
    const navigate = useNavigate()
    // INFO FOUNISSEURS
    let [fournisseur, setFournisseur] = useState(null);
    const [load, setLoad] = useState(false)
    // POUR CONTENEUR(CHOIX DES PRODUITS)
    let selectedProds = localStorage.getItem('selectedProducts') ? JSON.parse(localStorage.getItem('selectedProducts')) : null;
    let [prods, setProds] = useState(selectedProds ? selectedProds : []);
    const isSelected = (id) => {
        return prods?.find(p => p.id === id && p.qte > 0) ?? false
    }
    const handleSelectProduct = (e, prod) => {
        if (!(prods.find(p => p.id === prod.id))) {
            setProds([
                ...prods,
                {
                    'id': prod.id,
                    'qte': 1,
                    'prix': 0,
                    'prod': prod.produit,
                }
            ]);
        }
        handleCalulePrixTotal()
    }
    // POUR LA DEFINITION DES PRIX ET QUANTITE
    const [totalPrix, setTotalPrix] = useState(0);
    let [conteneur, setConteneur] = useState('');
    let [devise, setDevise] = useState('');
    let [depense, setDepense] = useState(0);
    let [valeurjour, setValeurJour] = useState(1);
    const [prixConteneur, setPrixConteneur] = useState(0);//PRIX DU CONTENEUR EN DEVISE
    const [qteTotal, setQteTotal] = useState(0);
    // informations du conteneur
    let MyInfo = {
        'lib_conteneur': conteneur,
        'devise': devise,
        'prix_total': parseFloat(prixConteneur),
        'valeur_du_jour': +valeurjour,
        'depense': + depense,
        'montant_achat': +totalPrix,
        'montant_total': (+valeurjour * prixConteneur) + (+depense),
    }
    const handleQte = (e, prod) => {
        prod.qte = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal();
        handleCaluleQteTotal();
    }
    const handlePrix = (e, prod) => {
        prod.prix = e.target.value.replace(/\D/g, "");
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal()
    }
    const handleCalulePrixTotal = () => {
        setTotalPrix(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.qte * (+currentValue.prix)) },
            0));
    }
    const handleCaluleQteTotal = () => {
        setQteTotal(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.qte) },
            0));
    }
    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (prods.find(p => p.id === prod.id)) {
            setProds(prods.filter(elm => elm.id !== prod.id))
        }
        handleCalulePrixTotal()

    }
    const handleSave = () => {
        // Control de la saisie des information du fournisseurs
        if (!fournisseur) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez selectionner le fournisseur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!devise) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez selectionner la devise des prix dans la section information du conteneur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!conteneur) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez saisir le libelé du conteneur dans la section information du conteneur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (prods?.length === 0) {
            Swal.fire({
                title: "Aucun produit selectionné!",
                text: "Veillez selectionner au moins un produit!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        let isvalide = -1;
        prods.forEach(p => {
            if (+p.qte < 1 || +p.prix < 1) {
                isvalide = +prods.indexOf(p) + 1;
            }
        });
        if (isvalide >= 0) {
            Swal.fire({
                title: "Erreur de saisie!",
                text: "Vériez le prix et la quantié sur la ligne " + isvalide,
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }

        if (!valeurjour) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez saisir la valeur du jour des produits en GNF!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return

        }
        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}conteneurs/ajout`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "fournisseur": fournisseur,
                "produits": prods,
                "conteneur": MyInfo
            })
        }).then((response) => {
            status = response.status;
            return response.json();

        }).then(data => {
            setLoad(false)
            if (status === 200) {

                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })
                deleteLocalStorage()
                navigate(`/conteneurs`)
            } else {
                Swal.fire({
                    title: "Erreur",
                    text: data.message,
                    icon: "error"
                })
            }
        })
    }
    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(prods));
        handleCalulePrixTotal()
    }, [prods, depense])


    useEffect(() => {
        handleCaluleQteTotal()
    }, [prods])

    return (
        <div className="add-conteneur">
            {load &&
               <div className="loader"></div>
            }
            <div className="container py-2 b">
                <h2 className="font-weight-light text-center text-muted py-3">CREATION DE CONTENEUR</h2>
                <div className="row ">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col-lg-6 py-1 ">
                        <h4 className="text-secondary">Fournisseur</h4>
                        <div className="input-group">
                            {loader ? <Select /> :
                                <Select className="w-75"
                                    defaultInputValue={fournisseur?.label}
                                    options={
                                        fournisseurs?.map((fourn) => (
                                            {
                                                value: `${fourn.id}`, label: `${fourn.prenoms} ${fourn.nom}(${fourn.telephone})`
                                            }
                                        ))
                                    }
                                    onChange={setFournisseur}
                                />
                            }
                            <a className="input-group-text bg-transparent btn btn-primary text-dark" data-bs-toggle='modal' data-bs-target='#modalAddFournisseur'>
                                <i className='bx bx-plus-circle'></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card border-primary shadow radius-15">
                            <div className="card-body" >
                                <h4 className="card-title text-muted">Informations du conteneur</h4>
                                <div className="row">
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Libelé du Conteneur</h5>
                                        <input type="text" className="form-control" name="conteneur" id="conteneur"
                                            defaultValue={conteneur}
                                            onChange={(e) => {
                                                setConteneur(e.target.value);
                                                localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                            }} />
                                    </div>
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Devise</h5>
                                        <select name="devise" id="deise" className="form-select" defaultValue={devise} onChange={(e) => {
                                            setDevise(e.target.value)
                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                        }}>
                                            <option value="">Choisir une devise</option>
                                            <option value="GNF">GNF</option>
                                            <option value="CFA">CFA</option>
                                            <option value="$">$</option>
                                            <option value="Euro">Euro</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Valeur du jour</h5>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Valeur du jour" defaultValue={valeurjour} onChange={(e) => {
                                                setValeurJour(+e.target.value)
                                                localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                            }}
                                                onBlur={(e) => formatMoney(e)}
                                                onFocus={(e) => onfocus(e)} />
                                            <span className="input-group-text" id="basic-addon2">GNF</span>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 p-2 flex-grow-1">
                                        <h5 className="card-title text-primary">Prix du conteneur en devise</h5>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Entrez le Montant facture en devise" defaultValue={prixConteneur} onChange={(e) => {
                                                setPrixConteneur(+e.target.value)
                                                localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                            }}
                                                onBlur={(e) => formatMoney(e)}
                                                onFocus={(e) => onfocus(e)} />
                                            <span className="input-group-text" id="basic-addon2">{devise}</span>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 p-2 flex-grow-1">
                                        <h5 className="card-title text-primary">Dépense totale</h5>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Entrez la depense" defaultValue={depense} onChange={(e) => {
                                                setDepense(+e.target.value)
                                                localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                            }}
                                                onBlur={(e) => formatMoney(e)}
                                                onFocus={(e) => onfocus(e)} />
                                            <span className="input-group-text" id="basic-addon2">GNF</span>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 p-2 flex-grow-1">
                                        <h5 className="card-title text-primary">Montant total en GNF</h5>

                                        <div className="input-group mb-3">
                                            <div className="p-2 bg-secondary-subtle  w-75">
                                                {((prixConteneur * valeurjour) + depense).toLocaleString()}

                                            </div>
                                            <span className="input-group-text" id="basic-addon2">GNF</span>
                                            <small className="text-secondary">(prix conteneur * valeur jour)+depense</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title text-muted">Choix des produits</h4>
                                <div className="row p-2">

                                    <div className="Prouits">
                                        {
                                            isloading ? <Loading /> : <div className="ListeProduits">

                                                <div className="row justify-content-end">
                                                    <div className="col-lg-4">  <SearchField plage='plage' conteneur='cont' critere='critere' /></div>
                                                </div>
                                                {
                                                    produits ?
                                                        <div className="row mt-2 p-2">
                                                            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 plage">
                                                                {
                                                                    produits.map((prod) => (
                                                                        <div className="col p-2 cont" key={prod.id} >
                                                                            <div className="card h-100  rounded-5 p-3 card-magasin pointer" >
                                                                                <h5 className="text-center critere">{prod.produit}</h5>
                                                                                <div className="row justify-content-between align-items-center">
                                                                                    <div className="col-lg-4">
                                                                                        {isSelected(prod.id) && <div className="row align-items-center">
                                                                                            <i className="bx bx-message-square-check fs-4 text-success" ></i>

                                                                                        </div>}
                                                                                    </div>
                                                                                    <div className="col-lg-6 text-center">
                                                                                        {!isSelected(prod.id) && <button className="btn btn-primary m-1" onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                                                        {isSelected(prod.id) && <button className="btn btn-danger m-1" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        : <div className="row p-3 m-2 bg-danger-subtle">
                                                            <h5 className="text-center">Aucun produit n'a ete enregister</h5>
                                                        </div>
                                                }
                                            </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Saisie des Quantiés & Prix</h4>
                                <div className="row p-2">
                                    <div className="table-responsive p-2 mt-2">
                                        {prods?.length ?


                                            <table className="table table-hover table-striped table-secondary  table-bordered">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <td hidden>ID</td>
                                                        <td >N°</td>
                                                        <td>Designation Produit</td>
                                                        <td>Qte</td>
                                                        <td >PAU</td>
                                                        <td className="text-end">Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody id="selectedProducts">
                                                    {
                                                        prods.map((pro, i) => (
                                                            <tr key={pro.id}>
                                                                <td hidden className="id"> {pro.id}</td>
                                                                <td > {i + 1}</td>
                                                                <td className="prod"> {pro.prod}</td>
                                                                <td className=" text-end qte">
                                                                    <input type="number" min={1} className="form-control" defaultValue={+pro.qte} onChange={(e) => handleQte(e, pro)} />

                                                                </td>
                                                                <td >

                                                                    <div className="input-group mb-3">
                                                                        <input type="text" className="form-control" placeholder="Entrez le prix unitaire" defaultValue={pro.prix} onChange={(e) => handlePrix(e, pro)} onBlur={(e) => formatMoney(e)}
                                                                            onFocus={(e) => onfocus(e)} />
                                                                        <span className="input-group-text" id="basic-addon2">GNF</span>
                                                                    </div>

                                                                </td>
                                                                <td className="text-end">

                                                                    <button onClick={(e) => handleUnSelectProduct(e, pro)} className="btn btn-danger m-1" >Retirer</button>
                                                                </td>
                                                            </tr>

                                                        ))
                                                    }
                                                    <tr className="border-0">
                                                        <td colSpan={2} className="text-center fs-4 fw-bold border"> Totaux</td>
                                                        <td className="border fw-bold">{qteTotal.toLocaleString()}</td>
                                                        <td className="border fw-bold">{`${totalPrix.toLocaleString()} GNF`}</td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <div className="bg-danger-subtle text-danger w-100 text-center p-2 fs-3 ">
                                                Vous n'avez pas choisi un produit!
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Validation</h4>
                                <div className="modal-footer">
                                    <button className="btn btn-danger m-1" onClick={() => deleteLocalStorage()}>Annuler</button>
                                    <button className="btn btn-success m-1" onClick={() => handleSave()}>Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalAddFournisseur />
        </div>
    );
}

export default Addconteneur;