import { useEffect, useState } from "react";
import { decryptData, defaulUrl, deleter } from "../components/const";

const Recouvrements = ({ id }) => {
    const datejour = (new Date()).toISOString().slice(0, 10);
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsloading] = useState(false);
    const [donnees, setData] = useState(null);
    const [totalPaye, setPaye] = useState(0);

    if (!id) {
        id = decryptData('user_profile').idmagasin;
    }
    const handleSend = () => {
        setIsloading(true);
        let status = 0;
        fetch(`${defaulUrl}paiements/${id}/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsloading(false);
            if (status === 200) {
                setData(da)

            }
        })
    };
    useEffect(() => {
        handleSend()
        if (donnees?.length) {
            setPaye(donnees?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
    }, [])
    useEffect(() => {

        if (donnees?.length) {
            setPaye(donnees?.reduce((previous, current) => { return previous + current.montant }, 0))
        }
    }, [donnees])
    return (
        <div className="recouvrement">
            <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                <div className="col-lg-6">
                    <div className="h4 text-primary">FANSAN TEXTIL-COMPTABILITY</div>
                </div>
                <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                    <div className="col-lg-6 h4 text-secondary ">
                        Recouvrement dettes clients
                    </div>
                    <div className="col-lg-6  ">
                        <div className="modal-footer align-items-center justify-content-end ">
                            <div className="col-lg-4 m-1 text-end ">
                                <input type="date" className="form-control " id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                            </div>
                            <div className="col-lg-4 m-1 text-end">
                                <input type="date" className="form-control " id="date1" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                            </div>
                            <div className="col-lg-2  text-end w-auto">
                                <button type="submit" className="btn btn-primary" onClick={() => handleSend()}><i className="bx bx-search"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {isLoading ? <div className='loader'></div> :
                donnees?.length ? <div className="table-responsive">
                    <table className="table table-striped table hover table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>N°FACTURE</th>
                                <th>MONTANT</th>
                                <th>CLIENT</th>
                                <th>TELEPHONE</th>
                                <th>DATE</th>
                                <th hidden>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                donnees?.map((paie, ind) =>
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{paie.numero}</td>
                                        <td>{paie.montant.toLocaleString()}</td>
                                        <td>{paie.client}</td>
                                        <td>{paie.telephone}</td>
                                        <td>{(new Date(paie.created_at)).toLocaleDateString()}</td>
                                        <td className="text-end" hidden>
                                            <button className="btn btn-danger" onClick={() => deleter(`paiement/delete/${paie.id}`)}><i className="bx bx-trash"></i></button>
                                        </td>
                                    </tr>
                                )
                            }

                            <tr className="bg-secondary-subtle fs-3 fw-bold align-middle text-center">
                                <td colSpan={2}>TOTAL</td>
                                <td className="text-success">{totalPaye?.toLocaleString()} GNF</td>
                            </tr>
                        </tbody>
                    </table>
                </div> : <div className="text-center w-100 p-2 fs-3 bg-danger-subtle text-danger">Aucun remboursement effectue</div>
            }

        </div>
    );
}

export default Recouvrements;