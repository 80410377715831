import { Loading, decryptData, defaulUrl } from "../components/const";
import { useEffect, useState } from "react";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import ModalRemboursement from "../Fournisseurs/ModalRemboursement";
import DetailFactures from "./DetailFacture";
import Paiements from "./Paiements";
import { NavLink } from "react-router-dom";
const FactureNonPayees = ({ idmagasin }) => {
    const user = decryptData('USER')
    const isMagasin = user.droit === 'Magasin';
    const [selectedFacture, setFacture] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [detailfacture, setDetailFacture] = useState([]);
    const [listPaiement, setPaiements] = useState([]);
    const [totalDette, settotalDette] = useState(0);
    if (!idmagasin) {
        idmagasin = decryptData('user_profile').idmagasin
    }
    let { data: factures, isloading } = UseFetch(`ventes/factures/${idmagasin}`);
    factures?.sort((a, b) => a.idfacture - b.idfacture)
    const handleSelectFacture = (fact) => {
        setFacture(fact)
    }
    const handleDetail = (e, id) => {
        setIsLoading(true)
        e.preventDefault()

        fetch(`${defaulUrl}factures/${id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setDetailFacture(data)
        })

    }
    const handleShowPaiements = (e, numero) => {
        setIsLoading(true)
        e.preventDefault()

        fetch(`${defaulUrl}paiements/${numero}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setPaiements(data)
        })

    }

    useEffect(() => {
        settotalDette(factures?.reduce((previousValue, current) => { return (previousValue) + (+current.reste) }, 0))
    }, [factures])
    return (
        isloading ? <Loading /> : factures?.length ? <div className="row">
            <div className="row p-2 justify-content-between align-items-center">
                <div className="col-lg-4 text-primary h3">
                    Factures impayées
                </div>
                <div className="col-lg-4 text-end">
                    <SearchField plage='plage' conteneur='tr' critere='critere' placeholder='Rechercher un client' />
                </div>
                <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>NUM_FAC</th>
                                <th>CLIENT</th>
                                <th>TELEPHONE</th>
                                <th>ADRESSE</th>
                                <th>MONTANT</th>
                                <th>PAYE</th>
                                <th>RESTE</th>
                                <th>DATE</th>
                                <th className="text-end">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                factures.map((fact, ind) => (
                                    <tr key={ind} className="tr">
                                        <td>{ind + 1}</td>
                                        <td>{fact.numero}</td>
                                        <td className="critere">{fact.client}</td>
                                        <td>{fact.telephone}</td>
                                        <td>{fact.adresse}</td>
                                        <td>{(+fact.montant).toLocaleString()}</td>
                                        <td>{(+fact.paye).toLocaleString()}</td>
                                        <td>{(+fact.reste).toLocaleString()}</td>
                                        <td>{(new Date(fact.created_at).toLocaleDateString())}</td>
                                        <td className="text-end">

                                            {isMagasin &&
                                                <NavLink to={`/print/${fact.idfacture}`} className="btn btn-danger m-1" title="Imprimer en pdf">
                                                    <i className="bx bx-download"></i>
                                                </NavLink>}
                                            {isMagasin && <button data-bs-toggle="modal" data-bs-target="#modalRemboursement" className="btn btn-success m-1" title="Faire un encaissement" onClick={() => handleSelectFacture(fact)}>
                                                <i className="bx bx-dollar-circle"></i>
                                            </button>}
                                            <button data-bs-toggle="modal" data-bs-target="#paiement" className="btn btn-primary m-1" title="Detail encaissements" onClick={(e) => handleShowPaiements(e, fact.numero)}>
                                                <i className="bx bx-task"></i>
                                            </button>
                                            <button data-bs-toggle="modal" data-bs-target="#detail" className="btn btn-info m-1" title="Detail facture" onClick={(e) => handleDetail(e, fact.idfacture)}>
                                                <i className="bx bx-list-ol"></i>
                                            </button>

                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className="fw-bold fs-3 text-center bg-danger-subtle">
                            <td colSpan={6}>TOTAL DETTE</td>
                            <td colSpan={2}>{totalDette?.toLocaleString()} GNF</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <DetailFactures data={detailfacture} isLoading={isLoading} />
            <ModalRemboursement facture={selectedFacture} table='factures' />
            <Paiements paiements={listPaiement} isLoading={isLoading} />
        </div> : <div className="row p-2 bg-danger-subtle text-danger justify-content-center fs-3">Aucune facture impayées!</div>
    );
}

export default FactureNonPayees;