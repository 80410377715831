import { useRef, useState } from "react";
import Select from 'react-select';
import { decryptData, defaulUrl, formatMoney, Loading, onfocus } from "../components/const";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";

const OperationsComptable = ({ titre, type }) => {
    const { data, isloading } = UseFetch('versements');
    let versements = data?.filter((p) => (p.status) === 'En attente')
    const [isLoading, setIsloading] = useState(false);
    const handleConfirm = (id, status) => {
        let code = 0;
        Swal.fire({
            title: "Suppression de donnees",
            text: `voulez-vous  vraiment ${status} ce versement?`,
            icon: 'question',
            showCancelButton: "true",
        }).then(conf => {
            if (conf.isConfirmed) {
                setIsloading(true);
                const formdata = new FormData();
                formdata.set('status', status);
                fetch(`${defaulUrl}versement/conifmation/${id}`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
                        Accept: 'Application/json'
                    },
                    body: formdata
                }).then(response => {
                    code = response.status;
                    return response.json();
                }).then(data => {
                    console.log(data)
                    if (code === 200) {
                        Swal.fire({
                            timer: 3000,
                            title: "Succes",
                            text: data.message,
                            icon: 'success'
                        })
                        setIsloading(false);
                    }
                })
            }
        })


    }
    return (
        <div className="modal fade " id="modalcomptable" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{titre}</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body position-relative">
                        {
                            (!data || isLoading) ? <Loading /> : versements?.length ?
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead className="table-primary">
                                            <tr>
                                                <th>N°</th>
                                                <th>MAGASIN</th>
                                                <th>SOLDE</th>
                                                <th>MONTANT VERSE</th>
                                                <th>DATE</th>
                                                <th>STATUT</th>
                                                <th className="text-end">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                versements?.map((vers, ind) => (
                                                    <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>{vers.magasin}</td>
                                                        <td>{vers.solde.toLocaleString()} GNF</td>
                                                        <td>{vers.montant.toLocaleString()} GNF</td>
                                                        <td>{(new Date(vers.date)).toLocaleDateString()}</td>
                                                        <td>{vers.status}</td>
                                                        <td className="text-end">
                                                            {(vers.status != 'confirmé') && <button className="btn btn-success m-1" onClick={() => {
                                                                handleConfirm(vers.id, 'confirmé')
                                                            }}><i className="bx bx-check"></i> Confirmer</button>}
                                                            {(vers.status != 'confirmé') && <button className="btn btn-danger m-1" onClick={() => {
                                                                handleConfirm(vers.id, 'rejeté')
                                                            }}><i className="bx bx-x"></i> Rejeter</button>}
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div> : <div className="w-100 text-center p-2 fs-3 text-danger bg-danger-subtle">Aucun versement ent attente</div>

                        }

                    </div>


                </div>
            </div>
        </div>
    );
}

export default OperationsComptable;