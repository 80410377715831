import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
const DetailAppro = () => {
    let { id } = useParams()
    const navigator = useNavigate()
    const { data, isloading } = UseFetch(`stocks-magasins-appro/${+id}`);

    let table = data?.produits;
    let totaux = data?.totaux;
    return (
        <div className="AddModal">
            <div className="row justify-content-between">
                <div className="col-lg-3">
                    <button className="btn btn-primary" onClick={() => navigator(-1)}  ><i className="bx bx-arrow-back"></i> Retour</button>
                </div>
                <div className="h3 text-primary col-lg-6 text-end">LISTE DES PRODUITS DU CONTENEUR</div>
            </div>
            <div className="">

                <div className="row p-2 table-responsive mt-2">
                    {isloading ? <Loading /> :
                        <table className="table table-striped table-hover shadow table-bordered border-primary">
                            <thead className="table-secondary border-primary">
                                <tr className="text-center">
                                    <th>N°</th>
                                    <th>Produit</th>
                                    <th className="bg-warning-subtle">PAU</th>
                                    <th>Qte appro</th>
                                    <th>Mont. Achat</th>
                                    <th className="bg-success-subtle">PVU</th>
                                    <th>Mont. Vente</th>
                                    <th>qte vendu</th>
                                    <th>qte restante</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    table.map((prod, index) => (
                                        <tr key={index + 1}>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td>{prod.produit}</td>
                                            <td className="bg-warning-subtle">{`${(prod.pau.toLocaleString())} GNF`}</td>
                                            <td>{prod.quantite}</td>
                                            <td>{`${((+prod.pau) * (+prod.quantite)).toLocaleString()} GNF`}</td>
                                            <td className="bg-success-subtle">{`${prod.pvu.toLocaleString()} GNF`}</td>
                                            <td>{`${((+prod.pvu) * (+prod.quantite)).toLocaleString()} GNF`}</td>
                                            <td>{prod.vendu}</td>
                                            <td>{+prod.quantite - (+prod.vendu)}</td>

                                        </tr>
                                    ))
                                }
                                <tr className="align-middle">
                                    <td colSpan={3} className="text-center fw-bold fs-3">Totaux</td>
                                    <td className="fw-bold">{totaux.qte}</td>
                                    <td className="fw-bold">{(+totaux.mpau).toLocaleString()} GNF</td>
                                    <td className="fw-bold"></td>
                                    <td className="fw-bold">{(+totaux.mpvu).toLocaleString()} GNF</td>
                                    <td className="fw-bold">{totaux.vendu}</td>
                                    <td className="fw-bold">{totaux.qte - totaux.vendu}</td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>


            </div>

        </div>
    );
}

export default DetailAppro;