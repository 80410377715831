

import { useEffect, useState } from "react";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import { Loading, defaulUrl, decryptData } from "../components/const";
import ModalRemboursement from "./ModalRemboursement";
import Paiements from "../Clients/Paiements";
import DetailConteneur from "../Contenaires/DetailConteneur";
import ModalRemboursementFournisseur from "../Comptability/ModalRemboursementFournisseur";

const FacturesImpayes = () => {
    const user=decryptData('USER');
    const { data: fournisseurs, isloading } = UseFetch('fournisseurs/factures');
    const [selectedFacture, setFacture] = useState(null)
    const [detailproduct, setDetailProduct] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [listPaiement, setPaiements] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalPaye, setTotalPaye] = useState(0);
    const [totalDette, setTotalDette] = useState(0);
    const handleSetSelect = (fact) => {
        setFacture(fact)

    }
    const handleDetail = (e, id) => {
        setIsLoading(true)
        e.preventDefault()
        fetch(`${defaulUrl}stocks/liste/${+id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setDetailProduct(data)
        })

    }

    const handleShowPaiements = (e, id) => {
        setIsLoading(true)
        e.preventDefault()
        fetch(`${defaulUrl}conteneur/${id}/paiements`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setIsLoading(false)
            setPaiements(data)
        })

    }
    useEffect(() => {
        setTotal(fournisseurs?.reduce((provious, current) => { return provious + current.montant }, 0))
        setTotalPaye(fournisseurs?.reduce((provious, current) => { return provious + current.paye }, 0))
        setTotalDette(fournisseurs?.reduce((provious, current) => { return provious + current.reste }, 0))
    }, [fournisseurs])

    return (
        <div className="Factures">
            <div className="row my-2">
                <div className="col-lg-6 h2 text-primary">
                    FACTURES NON PAYEES
                </div>

            </div>
            {
                isloading ? <Loading /> : fournisseurs?.length ?
                    <div className="row">
                        <div className="row justify-content-end">
                            <div className="col-lg-6 text-end">
                                <SearchField plage='plage' conteneur='tr' critere='numero' />
                            </div>
                        </div>
                        <div className="table-responsive p-2">
                            <table className="table table-hover table-striped table-bordered border-primary">
                                <thead className="table-primary border-primary text-center">
                                    <tr>
                                        <th>N°</th>
                                        <th>N°FACTURE</th>
                                        <th>CONTENEUR</th>
                                        <th>FOURNISSEUR</th>
                                        <th>TELEPHONE</th>
                                        <th>MONTANT TOTAL</th>
                                        <th>MONTANT PAYE</th>
                                        <th>RESTE A PAYER</th>
                                        <th className="text-end">ACTIONS</th>
                                    </tr>

                                </thead>
                                <tbody className="plage">
                                    {
                                        fournisseurs && fournisseurs.map((fourn, index) => (
                                            <tr key={index} className="tr">

                                                <td>{index + 1}</td>
                                                <td className="numero">{fourn.numero}</td>
                                                <td>{fourn.conteneur}</td>
                                                <td>{`${fourn.prenoms} ${fourn.nom}`}</td>
                                                <td>{fourn.telephone}</td>
                                                <td>{`${(+fourn.montant).toLocaleString()} ${fourn.devise}`}</td>
                                                <td>{`${(+fourn.paye).toLocaleString()} ${fourn.devise}`}</td>
                                                <td>{`${(+fourn.reste).toLocaleString()} ${fourn.devise}`}</td>
                                                <td className="text-end" >
                                                  {user.droit === 'Comptable' &&  <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#modalRemboursementFournisseur" onClick={() => handleSetSelect(fourn)}><i className="bx bx-dollar-circle"></i></button>}
                                                    <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#paiement" onClick={(e) => handleShowPaiements(e, fourn.idcont)}><i className="bx bx-task"></i> </button>
                                                    <button className="btn btn-info m-1" data-bs-toggle="modal" data-bs-target="#detailConteneur" onClick={(e) => handleDetail(e, fourn.idcont)}><i className="bx bx-show text-white"></i> </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="fw-bold fs-3  bg-secondary-subtle" hidden>
                                        <td colSpan={6} className="text-center">TOTAUX</td>
                                        <td >{total?.toLocaleString()} GNF</td>
                                        <td className="text-success">{totalPaye?.toLocaleString()} GNF</td>
                                        <td className="text-danger">{totalDette?.toLocaleString()} GNF</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    :
                    <div className="row justify-content-center p-2 bg-danger-subtle text-danger fs-5">
                        Aucun fournisseur trouvé!
                    </div>
            }

            {/* Modal de paye */}
            <ModalRemboursementFournisseur facture={selectedFacture} table='conteneur' />

            <Paiements paiements={listPaiement} isLoading={isLoading} />
            <DetailConteneur isLoading={isLoading} detailproduct={detailproduct} />
        </div>
    );
}

export default FacturesImpayes;