import { useEffect, useState } from "react";
import SearchField from "../components/SeachField";
import { decryptData, Loading, defaulUrl, deleter } from "../components/const";
import DetailFactures from "../Clients/DetailFacture";
import ModalRemboursement from "../Fournisseurs/ModalRemboursement";
import { NavLink } from "react-router-dom";

const VenteJour = ({ id }) => {
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsLoading] = useState(false)
    const [detailLoader, setDetailLoader] = useState(false)
    const [detailfacture, setDetailFacture] = useState([]);
    const [ventes, setVentes] = useState(null);
    const [selectedFacture, setFacture] = useState(null)
    const [totalVente, setTotalMontant] = useState(0);
    const [totalPaye, setPaye] = useState(0);
    const [totalReste, setReste] = useState(0);

    const user = decryptData('USER')
    if (!id) {
        id = decryptData('user_profile').idmagasin;
    }
    const [error, setError] = useState("Aucune vente effectuée pour la periode choisie")

    const handleShowByPeriode = () => {
        setIsLoading(true);
        let status = 0;
        fetch(`${defaulUrl}ventes/${id}/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsLoading(false);
            if (status === 200) {
                setVentes(da);
            }
        })
    }

    const handleDetail = (e, id) => {
        setDetailLoader(true)
        e.preventDefault()

        fetch(`${defaulUrl}factures/${id}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            return response.json();
        }).then(data => {
            setDetailLoader(false)
            setDetailFacture(data)
        })

    }

    const handleTotal = () => {
        setTotalMontant(ventes?.reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue.montant

            }, 0))
        setPaye(ventes?.reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue.paye

            }, 0))
        setReste(ventes?.reduce(
            (previousValue, currentValue) => {
                return previousValue + currentValue.reste

            }, 0))

    }
    useEffect(() => {
        handleShowByPeriode();

    }, [])
    useEffect(() => {
        handleTotal()
    }, [ventes])
    return (
        <div className="container-fluid p-0">
            <div className="row py-2">
                <h2 className="text-primary">LISTE DES VENTES</h2>
                <div className="col-lg-5 d-flex gap-1">
                    <div className="form-group  col-4">
                        <input type="date" className="form-control" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                    </div>
                    <div className="form-group col-4 ">
                        <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary col-2 align-self-end" onClick={() => handleShowByPeriode()}><i className="bx bx-search" ></i></button>
                </div>
            </div>

            {
                isLoading ? <Loading /> : ((ventes?.length || !ventes)) ? <div className="vente container-fluid">
                    <div className="row justify-content-end p-2 align-items-center">

                        <div className="col-lg-6 text-end">
                            <SearchField plage='plage' conteneur='tr' critere='critere' placeholder='Rechercher par client' />
                        </div>
                    </div>
                    <div className="row px-1">
                        <div className="table-responsive mx-0 px-0 ">
                            <table className="table  table-hover table-striped shadow table-bordered">
                                <thead className="table-dark text-center">
                                    <tr className="align-middle">
                                        <th>N°</th>
                                        <th>N°FACTURE</th>
                                        <th>CLIENT</th>
                                        <th>TELEPHONE</th>
                                        <th>MONTANT</th>
                                        <th>PAYE</th>
                                        <th>RESTE</th>
                                        <th>DATE</th>
                                        <th className="text-end">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody className="plage">
                                    {
                                        ventes?.map((vente, ind) => (
                                            <tr key={ind} className="tr align-middle">
                                                <td>{ind + 1}</td>
                                                <td>{vente?.numero}</td>
                                                <td className="critere">{vente?.client}</td>
                                                <td>{vente?.telephone}</td>
                                                <td>{vente?.montant.toLocaleString()}</td>
                                                <td>{vente?.paye.toLocaleString()}</td>
                                                <td>{vente?.reste.toLocaleString()}</td>
                                                <td>{new Date(vente?.created_at).toLocaleDateString()}</td>
                                                <td className="text-end">
                                                    {user.droit === 'Magasin' && <NavLink className="btn btn-danger m-1" to={`/print/${vente.idfacture}`}><i className="bx bx-download"></i></NavLink>}
                                                    {user.droit === 'Magasin' && <button className="btn btn-success m-1" data-bs-toggle='modal' data-bs-target='#modalRemboursement' onClick={() => setFacture(vente)}><i className="bx bx-credit-card"></i></button>}
                                                    <button className="btn btn-info m-1" data-bs-toggle='modal' data-bs-target='#detail' onClick={(e) => handleDetail(e, vente.idfacture)}><i className="bx bx-list-ol"></i></button>
                                                    {/* {user.droit === 'Magasin' && <button className="btn btn-primary m-1"><i className="bx bx-edit"></i></button>} */}

                                                    {user.droit === 'Magasin' && <button className="btn btn-danger m-1" onClick={() => deleter(`factures/delete/${vente.idfacture}`)}><i className="bx bx-trash"></i></button>}
                                                </td>
                                            </tr>

                                        )
                                        )

                                    }
                                    <tr className="bg-secondary-subtle fs-3 fw-bold align-middle text-center">
                                        <td colSpan={4}>TOTAUX</td>
                                        <td>{totalVente?.toLocaleString()} GNF</td>
                                        <td className="text-success">{totalPaye?.toLocaleString()} GNF</td>
                                        <td className="text-danger">{totalReste?.toLocaleString()} GNF</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-3">{error}!</div>
            }


            <DetailFactures data={detailfacture} isLoading={detailLoader} />
            <ModalRemboursement facture={selectedFacture} table='factures' />
            {/* <Paiements paiements={listPaiement} isLoading={isLoading} /> */}
        </div>

    );
}

export default VenteJour;