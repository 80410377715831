import { NavLink, Outlet, Navigate } from "react-router-dom";
import { decryptData,  photoUrl } from "../components/const";
import MenuAdnin from "./MenuAdmin";
import MenuMagasin from "./MenuMagasin";
import Password from "../Authentification/Password";
import MenuComptable from "./MenuComptable";


const DefaultLayout = () => {
    const handleShowMenu = (e) => {
        const wrapper = document.querySelector('.wrapper')
        wrapper.classList.toggle('toggled')
    }



    if (!decryptData('ACCESS_TOKEN')) {
        return <Navigate to='/login' />
    }
    const user = decryptData('USER');

    return (
        <div className="Dashboard wrapper ">
            <div className="sidebar-wrapper" data-simplebar="true" >
                <div className="sidebar-header">
                    <div className="p-0 m-0">
                        <img src={`${photoUrl}logo.png`} className="logo-icon" alt="logo icon " />
                    </div>
                    <div className="m-0 p-0">
                        <h5 className="logo-text m-0 p-0">FasanTextil</h5>
                    </div>
                    <div className="toggle-icon ms-auto" onClick={handleShowMenu}><i className='bx fs-4  bx-arrow-to-left'></i>
                    </div>
                </div>
                {user.droit === 'Administrateur' && <MenuAdnin />}
                {user.droit === 'Magasin' && <MenuMagasin />}
                {user.droit === 'Comptable' && <MenuComptable />}
            </div>
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand">
                        <div className="mobile-toggle-menu" onClick={handleShowMenu}><i className='bx fs-4 bx-menu'></i>
                        </div>
                        <div className="setting-user-box ms-auto  d-flex justify-content-end align-items-center">
                            <NavLink hidden to='/parametres/aide' className='text-secondary'>
                                <i className="bx bx-help-circle fs-3"></i>
                            </NavLink>
                            {user.droit === 'Administrateur' && <div className=" dropdown ms-auto">
                                <NavLink className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bx bx-cog fs-3"></i>
                                </NavLink>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><NavLink to='/parametres/pays' className="dropdown-item" >
                                        <i className=" fs-4 "></i><span>Gestion Pays</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><NavLink to='/parametres/villes' className="dropdown-item " >
                                        <i className='fs-4 '></i><span>Gestion Villes</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><NavLink to='/users' className="dropdown-item" >
                                        <i className=" fs-4 "></i><span>Gestion Utilisateurs</span></NavLink>
                                    </li>

                                </ul>
                            </div>}
                            <div className="user-box dropdown ms-auto">
                                <NavLink className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={`${photoUrl}${user.photo}`} className="user-img" alt="user avatar" />
                                    <div className="user-info ps-3">
                                        <p className="user-name mb-0">{user?.email}</p>
                                        <p className="designattion mb-0"> {user?.droit}</p>
                                    </div>
                                </NavLink>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><NavLink to='/users/profile' className="dropdown-item" ><i className="bx fs-4 bx-user"></i><span>Mon profile</span></NavLink>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><button data-bs-toggle='modal' data-bs-target='#modalPassword' className="dropdown-item" ><i className="bx fs-4 bx-hide"></i><span>Mot de passe</span></button>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider mb-0"></div>
                                    </li>
                                    <li><NavLink to='/login' onClick={() => localStorage.clear()} className="dropdown-item text-danger" ><i className='bx fs-4 bx-log-out-circle'></i>Se déconnecter</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <div className="page-wrapper">
                <div className="page-content ">
                    <Outlet />
                </div>
            </div>


            <div className="overlay toggle-icon"></div>

            <NavLink className="back-to-top"><i className='bx fs-4 bxs-up-arrow-alt'></i></NavLink>

            <footer className="page-footer">
                <p className="mb-0">Copyright © 2024. Tout droit reservé.</p>
            </footer>


            <Password />
        </div>
    );
}


export default DefaultLayout;