import { impression } from "../components/const";

const Printer = ({ element }) => {

    return (

        <div className="printer">
            {element}
        </div>
    );
}

export default Printer;